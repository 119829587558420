/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Sonos Control API
 * The Sonos Control API is the primary means by which your application will interact with Sonos groups. First, discover available groups and players. Then use Control API commands to control players. For example, perform basic transport control such as play, pause, or skip. Or subscribe to receive events from the player, like track metadata.
 *
 * OpenAPI spec version: v2.0.0-production
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
import * as url from "url";
import isomorphicFetch from "isomorphic-fetch";
const BASE_PATH = "https://sonos3.fishertech.org/https://api.ws.sonos.com/control/api/v2".replace(/\/+$/, "");
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    constructor(configuration, basePath = BASE_PATH, fetch = isomorphicFetch) {
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(field, msg) {
        super(msg);
        this.field = field;
    }
}
/**
 * This enumeration identifies the current status of an audio clip.
 * @export
 * @enum {string}
 */
export var AudioClipState;
(function (AudioClipState) {
    AudioClipState[AudioClipState["ACTIVE"] = 'ACTIVE'] = "ACTIVE";
    AudioClipState[AudioClipState["DONE"] = 'DONE'] = "DONE";
    AudioClipState[AudioClipState["DISMISSED"] = 'DISMISSED'] = "DISMISSED";
    AudioClipState[AudioClipState["INACTIVE"] = 'INACTIVE'] = "INACTIVE";
    AudioClipState[AudioClipState["INTERRUPTED"] = 'INTERRUPTED'] = "INTERRUPTED";
    AudioClipState[AudioClipState["ERROR"] = 'ERROR'] = "ERROR";
})(AudioClipState || (AudioClipState = {}));
/**
 * This enumeration identifies the sounds that are built into the Sonos firmware. Partners are free to use these sounds with the loadAudioClip command instead of providing custom files.
 * @export
 * @enum {string}
 */
export var AudioClipType;
(function (AudioClipType) {
    AudioClipType[AudioClipType["CHIME"] = 'CHIME'] = "CHIME";
    AudioClipType[AudioClipType["CUSTOM"] = 'CUSTOM'] = "CUSTOM";
    AudioClipType[AudioClipType["VOICEASSISTANT"] = 'VOICE_ASSISTANT'] = "VOICEASSISTANT";
})(AudioClipType || (AudioClipType = {}));
/**
 * No description available
 * @export
 * @enum {string}
 */
export var Capability;
(function (Capability) {
    Capability[Capability["PLAYBACK"] = 'PLAYBACK'] = "PLAYBACK";
    Capability[Capability["CLOUD"] = 'CLOUD'] = "CLOUD";
    Capability[Capability["HTPLAYBACK"] = 'HT_PLAYBACK'] = "HTPLAYBACK";
    Capability[Capability["HTPOWERSTATE"] = 'HT_POWER_STATE'] = "HTPOWERSTATE";
    Capability[Capability["AIRPLAY"] = 'AIRPLAY'] = "AIRPLAY";
    Capability[Capability["LINEIN"] = 'LINE_IN'] = "LINEIN";
    Capability[Capability["AUDIOCLIP"] = 'AUDIO_CLIP'] = "AUDIOCLIP";
    Capability[Capability["VOICE"] = 'VOICE'] = "VOICE";
    Capability[Capability["SPEAKERDETECTION"] = 'SPEAKER_DETECTION'] = "SPEAKERDETECTION";
    Capability[Capability["FIXEDVOLUME"] = 'FIXED_VOLUME'] = "FIXEDVOLUME";
    Capability[Capability["ROOMDETECTION"] = 'ROOM_DETECTION'] = "ROOMDETECTION";
})(Capability || (Capability = {}));
/**
 * No description available
 * @export
 * @enum {string}
 */
export var GroupStatusCodes;
(function (GroupStatusCodes) {
    GroupStatusCodes[GroupStatusCodes["GONE"] = 'GROUP_STATUS_GONE'] = "GONE";
    GroupStatusCodes[GroupStatusCodes["MOVED"] = 'GROUP_STATUS_MOVED'] = "MOVED";
    GroupStatusCodes[GroupStatusCodes["UPDATED"] = 'GROUP_STATUS_UPDATED'] = "UPDATED";
})(GroupStatusCodes || (GroupStatusCodes = {}));
/**
 * No description available
 * @export
 * @enum {string}
 */
export var PlaybackState;
(function (PlaybackState) {
    PlaybackState[PlaybackState["IDLE"] = 'PLAYBACK_STATE_IDLE'] = "IDLE";
    PlaybackState[PlaybackState["BUFFERING"] = 'PLAYBACK_STATE_BUFFERING'] = "BUFFERING";
    PlaybackState[PlaybackState["PAUSED"] = 'PLAYBACK_STATE_PAUSED'] = "PAUSED";
    PlaybackState[PlaybackState["PLAYING"] = 'PLAYBACK_STATE_PLAYING'] = "PLAYING";
})(PlaybackState || (PlaybackState = {}));
/**
 * Sonos uses this enumeration to order concurrent clips.
 * @export
 * @enum {string}
 */
export var Priority;
(function (Priority) {
    Priority[Priority["LOW"] = 'LOW'] = "LOW";
    Priority[Priority["HIGH"] = 'HIGH'] = "HIGH";
})(Priority || (Priority = {}));
/**
 * No description available
 * @export
 * @enum {string}
 */
export var QueueAction;
(function (QueueAction) {
    QueueAction[QueueAction["REPLACE"] = 'REPLACE'] = "REPLACE";
    QueueAction[QueueAction["APPEND"] = 'APPEND'] = "APPEND";
    QueueAction[QueueAction["INSERT"] = 'INSERT'] = "INSERT";
    QueueAction[QueueAction["INSERTNEXT"] = 'INSERT_NEXT'] = "INSERTNEXT";
})(QueueAction || (QueueAction = {}));
/**
 * No description available
 * @export
 * @enum {string}
 */
export var SessionStateEnum;
(function (SessionStateEnum) {
    SessionStateEnum[SessionStateEnum["SESSIONSTATECONNECTED"] = 'SESSION_STATE_CONNECTED'] = "SESSIONSTATECONNECTED";
})(SessionStateEnum || (SessionStateEnum = {}));
/**
 * This generic enum is used to identify information about content. It is too generic,  as only one key has been defined in 5 years. Therefore, this enum is deprecated in  favor of the `explicit` parameter.
 * @export
 * @enum {string}
 */
export var TagsData;
(function (TagsData) {
    TagsData[TagsData["TAGEXPLICIT"] = 'TAG_EXPLICIT'] = "TAGEXPLICIT";
})(TagsData || (TagsData = {}));
/**
 * This enum represents the options to control the TV power state. More states could be added in the future.
 * @export
 * @enum {string}
 */
export var TvPowerState;
(function (TvPowerState) {
    TvPowerState[TvPowerState["ON"] = 'ON'] = "ON";
    TvPowerState[TvPowerState["STANDBY"] = 'STANDBY'] = "STANDBY";
})(TvPowerState || (TvPowerState = {}));
/**
 * No description available
 * @export
 * @enum {string}
 */
export var VolumeMode;
(function (VolumeMode) {
    VolumeMode[VolumeMode["VARIABLE"] = 'VARIABLE'] = "VARIABLE";
    VolumeMode[VolumeMode["FIXED"] = 'FIXED'] = "FIXED";
    VolumeMode[VolumeMode["PASSTHROUGH"] = 'PASS_THROUGH'] = "PASSTHROUGH";
})(VolumeMode || (VolumeMode = {}));
/**
 * AudioClipApi - fetch parameter creator
 * @export
 */
export const AudioClipApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use the cancelAudioClip command in the audioClip namespace to cancel a specific audio clip from playing. Sonos sends an error if it has already finished playing the selected audio clip.
         * @summary cancelAudioClip
         * @param {string} playerId Target playerId
         * @param {string} id Target id
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipCancelAudioClip(playerId, id, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling audioClipCancelAudioClip.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling audioClipCancelAudioClip.');
            }
            const localVarPath = `/players/{playerId}/audioClip/{id}`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the loadAudioClip command in the audioClip namespace to schedule an audio clip for playback. The command returns immediately, indicating whether the audio clip was successfully scheduled or not.   The player can handle multiple audio clips and implements a simple priority system to determine playback order:   A High priority audio clip can interrupt another high priority audio clip if the latter has played for more then 10 seconds.  A high priority audio clip can interrupt a low priority audio clip at any time.  A low priority audio clip can interrupt a low priority audio clip at any time.
         * @summary loadAudioClip
         * @param {string} playerId Target playerId
         * @param {AudioClipLoadAudioClipBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipLoadAudioClip(playerId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling audioClipLoadAudioClip.');
            }
            const localVarPath = `/players/{playerId}/audioClip`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("AudioClipLoadAudioClipBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the audioClip namespace.
         * @summary subscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipSubscribe(playerId, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling audioClipSubscribe.');
            }
            const localVarPath = `/players/{playerId}/audioClip/subscription`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the audioClip namespace.
         * @summary unsubscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipUnsubscribe(playerId, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling audioClipUnsubscribe.');
            }
            const localVarPath = `/players/{playerId}/audioClip/subscription`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AudioClipApi - functional programming interface
 * @export
 */
export const AudioClipApiFp = function (configuration) {
    return {
        /**
         * Use the cancelAudioClip command in the audioClip namespace to cancel a specific audio clip from playing. Sonos sends an error if it has already finished playing the selected audio clip.
         * @summary cancelAudioClip
         * @param {string} playerId Target playerId
         * @param {string} id Target id
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipCancelAudioClip(playerId, id, xSonosCorrId, options) {
            const localVarFetchArgs = AudioClipApiFetchParamCreator(configuration).audioClipCancelAudioClip(playerId, id, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the loadAudioClip command in the audioClip namespace to schedule an audio clip for playback. The command returns immediately, indicating whether the audio clip was successfully scheduled or not.   The player can handle multiple audio clips and implements a simple priority system to determine playback order:   A High priority audio clip can interrupt another high priority audio clip if the latter has played for more then 10 seconds.  A high priority audio clip can interrupt a low priority audio clip at any time.  A low priority audio clip can interrupt a low priority audio clip at any time.
         * @summary loadAudioClip
         * @param {string} playerId Target playerId
         * @param {AudioClipLoadAudioClipBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipLoadAudioClip(playerId, body, xSonosCorrId, options) {
            const localVarFetchArgs = AudioClipApiFetchParamCreator(configuration).audioClipLoadAudioClip(playerId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the audioClip namespace.
         * @summary subscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipSubscribe(playerId, xSonosCorrId, options) {
            const localVarFetchArgs = AudioClipApiFetchParamCreator(configuration).audioClipSubscribe(playerId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the audioClip namespace.
         * @summary unsubscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipUnsubscribe(playerId, xSonosCorrId, options) {
            const localVarFetchArgs = AudioClipApiFetchParamCreator(configuration).audioClipUnsubscribe(playerId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * AudioClipApi - factory interface
 * @export
 */
export const AudioClipApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use the cancelAudioClip command in the audioClip namespace to cancel a specific audio clip from playing. Sonos sends an error if it has already finished playing the selected audio clip.
         * @summary cancelAudioClip
         * @param {string} playerId Target playerId
         * @param {string} id Target id
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipCancelAudioClip(playerId, id, xSonosCorrId, options) {
            return AudioClipApiFp(configuration).audioClipCancelAudioClip(playerId, id, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the loadAudioClip command in the audioClip namespace to schedule an audio clip for playback. The command returns immediately, indicating whether the audio clip was successfully scheduled or not.   The player can handle multiple audio clips and implements a simple priority system to determine playback order:   A High priority audio clip can interrupt another high priority audio clip if the latter has played for more then 10 seconds.  A high priority audio clip can interrupt a low priority audio clip at any time.  A low priority audio clip can interrupt a low priority audio clip at any time.
         * @summary loadAudioClip
         * @param {string} playerId Target playerId
         * @param {AudioClipLoadAudioClipBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipLoadAudioClip(playerId, body, xSonosCorrId, options) {
            return AudioClipApiFp(configuration).audioClipLoadAudioClip(playerId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the audioClip namespace.
         * @summary subscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipSubscribe(playerId, xSonosCorrId, options) {
            return AudioClipApiFp(configuration).audioClipSubscribe(playerId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the audioClip namespace.
         * @summary unsubscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        audioClipUnsubscribe(playerId, xSonosCorrId, options) {
            return AudioClipApiFp(configuration).audioClipUnsubscribe(playerId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * AudioClipApi - object-oriented interface
 * @export
 * @class AudioClipApi
 * @extends {BaseAPI}
 */
export class AudioClipApi extends BaseAPI {
    /**
     * Use the cancelAudioClip command in the audioClip namespace to cancel a specific audio clip from playing. Sonos sends an error if it has already finished playing the selected audio clip.
     * @summary cancelAudioClip
     * @param {string} playerId Target playerId
     * @param {string} id Target id
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioClipApi
     */
    audioClipCancelAudioClip(playerId, id, xSonosCorrId, options) {
        return AudioClipApiFp(this.configuration).audioClipCancelAudioClip(playerId, id, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the loadAudioClip command in the audioClip namespace to schedule an audio clip for playback. The command returns immediately, indicating whether the audio clip was successfully scheduled or not.   The player can handle multiple audio clips and implements a simple priority system to determine playback order:   A High priority audio clip can interrupt another high priority audio clip if the latter has played for more then 10 seconds.  A high priority audio clip can interrupt a low priority audio clip at any time.  A low priority audio clip can interrupt a low priority audio clip at any time.
     * @summary loadAudioClip
     * @param {string} playerId Target playerId
     * @param {AudioClipLoadAudioClipBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioClipApi
     */
    audioClipLoadAudioClip(playerId, body, xSonosCorrId, options) {
        return AudioClipApiFp(this.configuration).audioClipLoadAudioClip(playerId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the audioClip namespace.
     * @summary subscribe
     * @param {string} playerId Target playerId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioClipApi
     */
    audioClipSubscribe(playerId, xSonosCorrId, options) {
        return AudioClipApiFp(this.configuration).audioClipSubscribe(playerId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the audioClip namespace.
     * @summary unsubscribe
     * @param {string} playerId Target playerId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioClipApi
     */
    audioClipUnsubscribe(playerId, xSonosCorrId, options) {
        return AudioClipApiFp(this.configuration).audioClipUnsubscribe(playerId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * FavoritesApi - fetch parameter creator
 * @export
 */
export const FavoritesApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use the getFavorites command in the favorites namespace to get the list of Sonos favorites for a household. Favorites do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos playlists. See the Save your favorites section in the Sonos user guide and Add favorites for details. The player limits the number of Sonos favorites to 70.  An array of objects is returned such that a developer can implement the favorites picker from the Sonos controller.
         * @summary getFavorites
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesGetFavorites(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling favoritesGetFavorites.');
            }
            const localVarPath = `/households/{householdId}/favorites`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The loadFavorite command in the favorites namespace activates a favorite within the default playback session. This command interrupts any active private playback sessions. Sonos adds album, tracklist, and track favorites to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
         * @summary loadFavorite
         * @param {string} groupId Target groupId
         * @param {FavoritesLoadFavoriteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesLoadFavorite(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling favoritesLoadFavorite.');
            }
            const localVarPath = `/groups/{groupId}/favorites`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("FavoritesLoadFavoriteBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the favorites namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos favorites. Your app can then choose to fetch the favorites as needed whenever the version changes. This is because the FavoritesList object can be large for asynchronous events.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesSubscribe(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling favoritesSubscribe.');
            }
            const localVarPath = `/households/{householdId}/favorites/subscription`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the favorites namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesUnsubscribe(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling favoritesUnsubscribe.');
            }
            const localVarPath = `/households/{householdId}/favorites/subscription`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * FavoritesApi - functional programming interface
 * @export
 */
export const FavoritesApiFp = function (configuration) {
    return {
        /**
         * Use the getFavorites command in the favorites namespace to get the list of Sonos favorites for a household. Favorites do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos playlists. See the Save your favorites section in the Sonos user guide and Add favorites for details. The player limits the number of Sonos favorites to 70.  An array of objects is returned such that a developer can implement the favorites picker from the Sonos controller.
         * @summary getFavorites
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesGetFavorites(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = FavoritesApiFetchParamCreator(configuration).favoritesGetFavorites(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * The loadFavorite command in the favorites namespace activates a favorite within the default playback session. This command interrupts any active private playback sessions. Sonos adds album, tracklist, and track favorites to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
         * @summary loadFavorite
         * @param {string} groupId Target groupId
         * @param {FavoritesLoadFavoriteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesLoadFavorite(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = FavoritesApiFetchParamCreator(configuration).favoritesLoadFavorite(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the favorites namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos favorites. Your app can then choose to fetch the favorites as needed whenever the version changes. This is because the FavoritesList object can be large for asynchronous events.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesSubscribe(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = FavoritesApiFetchParamCreator(configuration).favoritesSubscribe(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the favorites namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesUnsubscribe(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = FavoritesApiFetchParamCreator(configuration).favoritesUnsubscribe(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * FavoritesApi - factory interface
 * @export
 */
export const FavoritesApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use the getFavorites command in the favorites namespace to get the list of Sonos favorites for a household. Favorites do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos playlists. See the Save your favorites section in the Sonos user guide and Add favorites for details. The player limits the number of Sonos favorites to 70.  An array of objects is returned such that a developer can implement the favorites picker from the Sonos controller.
         * @summary getFavorites
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesGetFavorites(householdId, xSonosCorrId, options) {
            return FavoritesApiFp(configuration).favoritesGetFavorites(householdId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * The loadFavorite command in the favorites namespace activates a favorite within the default playback session. This command interrupts any active private playback sessions. Sonos adds album, tracklist, and track favorites to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
         * @summary loadFavorite
         * @param {string} groupId Target groupId
         * @param {FavoritesLoadFavoriteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesLoadFavorite(groupId, body, xSonosCorrId, options) {
            return FavoritesApiFp(configuration).favoritesLoadFavorite(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the favorites namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos favorites. Your app can then choose to fetch the favorites as needed whenever the version changes. This is because the FavoritesList object can be large for asynchronous events.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesSubscribe(householdId, xSonosCorrId, options) {
            return FavoritesApiFp(configuration).favoritesSubscribe(householdId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the favorites namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritesUnsubscribe(householdId, xSonosCorrId, options) {
            return FavoritesApiFp(configuration).favoritesUnsubscribe(householdId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * FavoritesApi - object-oriented interface
 * @export
 * @class FavoritesApi
 * @extends {BaseAPI}
 */
export class FavoritesApi extends BaseAPI {
    /**
     * Use the getFavorites command in the favorites namespace to get the list of Sonos favorites for a household. Favorites do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos playlists. See the Save your favorites section in the Sonos user guide and Add favorites for details. The player limits the number of Sonos favorites to 70.  An array of objects is returned such that a developer can implement the favorites picker from the Sonos controller.
     * @summary getFavorites
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApi
     */
    favoritesGetFavorites(householdId, xSonosCorrId, options) {
        return FavoritesApiFp(this.configuration).favoritesGetFavorites(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * The loadFavorite command in the favorites namespace activates a favorite within the default playback session. This command interrupts any active private playback sessions. Sonos adds album, tracklist, and track favorites to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
     * @summary loadFavorite
     * @param {string} groupId Target groupId
     * @param {FavoritesLoadFavoriteBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApi
     */
    favoritesLoadFavorite(groupId, body, xSonosCorrId, options) {
        return FavoritesApiFp(this.configuration).favoritesLoadFavorite(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the favorites namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos favorites. Your app can then choose to fetch the favorites as needed whenever the version changes. This is because the FavoritesList object can be large for asynchronous events.
     * @summary subscribe
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApi
     */
    favoritesSubscribe(householdId, xSonosCorrId, options) {
        return FavoritesApiFp(this.configuration).favoritesSubscribe(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the favorites namespace.
     * @summary unsubscribe
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApi
     */
    favoritesUnsubscribe(householdId, xSonosCorrId, options) {
        return FavoritesApiFp(this.configuration).favoritesUnsubscribe(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * GroupVolumeApi - fetch parameter creator
 * @export
 */
export const GroupVolumeApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use the getVolume command in the groupVolume namespace to get the volume and mute state of a group.
         * @summary getVolume
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeGetVolume(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupVolumeGetVolume.');
            }
            const localVarPath = `/groups/{groupId}/groupVolume`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setMute command in the groupVolume namespace to mute and unmute the group.   The group’s mute state is independent of its group volume. For example a group’s mute state can be true although its group volume is still 20, and the user will not hear any audio coming from the group of players. Individual player volume values are stored by each player in the group while muted, which allows the group to retain the relative volume of players in the group. That is important information to preserve, since users may have carefully configured the individual player volumes in the group with a Sonos controller. Your app should not mute the group by calling setVolume with zero volume, otherwise the relative volume of players in the group will be lost.   If some players in the group are muted and others are not, calling setMute to mute the group will mute all the players in the group. The individual player’s mute state prior to calling setMute is not preserved.   To get the current group’s mute state, use the getVolume command or subscribe to the groupVolume event.
         * @summary setMute
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetMuteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetMute(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupVolumeSetMute.');
            }
            const localVarPath = `/groups/{groupId}/groupVolume/mute`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("GroupVolumeSetMuteBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setRelativeVolume command in the groupVolume namespace to increase or decrease group volume, and unmute the group if muted.   Your app can use setRelativeVolume instead of setVolume when the user intent is to increase or decrease the group volume, but not to set the final volume to a particular value. For example, your app should use setRelativeVolume when the user presses hard volume plus/minus buttons on a mobile device.   The group will automatically limit the final volume set within the valid range, so your app does not need to worry about that.   See setVolume for more details on how group volume works.
         * @summary setRelativeVolume
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetRelativeVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetRelativeVolume(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupVolumeSetRelativeVolume.');
            }
            const localVarPath = `/groups/{groupId}/groupVolume/relative`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("GroupVolumeSetRelativeVolumeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setVolume command in the groupVolume namespace to set group volume to a specific level and unmute the group if muted.   When your app sets the group volume, the group coordinator calculates the required changes to the volume level for each player in the group so that the result is the average volume level of the group as a whole. The group coordinator then adjusts its local volume and initiates network transactions with the other players in the group to adjust their volumes accordingly. While players adjust their volumes, the group coordinator may generate one or more groupVolume events.   Since this command can generate additional network transactions and events, your app should optimize how often it is invoked to maintain a good user experience without flooding the network, such as when implementing a touch screen volume slider that controls a group of five players.
         * @summary setVolume
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetVolume(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupVolumeSetVolume.');
            }
            const localVarPath = `/groups/{groupId}/groupVolume`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("GroupVolumeSetVolumeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the groupVolume namespace.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSubscribe(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupVolumeSubscribe.');
            }
            const localVarPath = `/groups/{groupId}/groupVolume/subscription`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the groupVolume namespace.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeUnsubscribe(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupVolumeUnsubscribe.');
            }
            const localVarPath = `/groups/{groupId}/groupVolume/subscription`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GroupVolumeApi - functional programming interface
 * @export
 */
export const GroupVolumeApiFp = function (configuration) {
    return {
        /**
         * Use the getVolume command in the groupVolume namespace to get the volume and mute state of a group.
         * @summary getVolume
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeGetVolume(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = GroupVolumeApiFetchParamCreator(configuration).groupVolumeGetVolume(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setMute command in the groupVolume namespace to mute and unmute the group.   The group’s mute state is independent of its group volume. For example a group’s mute state can be true although its group volume is still 20, and the user will not hear any audio coming from the group of players. Individual player volume values are stored by each player in the group while muted, which allows the group to retain the relative volume of players in the group. That is important information to preserve, since users may have carefully configured the individual player volumes in the group with a Sonos controller. Your app should not mute the group by calling setVolume with zero volume, otherwise the relative volume of players in the group will be lost.   If some players in the group are muted and others are not, calling setMute to mute the group will mute all the players in the group. The individual player’s mute state prior to calling setMute is not preserved.   To get the current group’s mute state, use the getVolume command or subscribe to the groupVolume event.
         * @summary setMute
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetMuteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetMute(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = GroupVolumeApiFetchParamCreator(configuration).groupVolumeSetMute(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setRelativeVolume command in the groupVolume namespace to increase or decrease group volume, and unmute the group if muted.   Your app can use setRelativeVolume instead of setVolume when the user intent is to increase or decrease the group volume, but not to set the final volume to a particular value. For example, your app should use setRelativeVolume when the user presses hard volume plus/minus buttons on a mobile device.   The group will automatically limit the final volume set within the valid range, so your app does not need to worry about that.   See setVolume for more details on how group volume works.
         * @summary setRelativeVolume
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetRelativeVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetRelativeVolume(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = GroupVolumeApiFetchParamCreator(configuration).groupVolumeSetRelativeVolume(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setVolume command in the groupVolume namespace to set group volume to a specific level and unmute the group if muted.   When your app sets the group volume, the group coordinator calculates the required changes to the volume level for each player in the group so that the result is the average volume level of the group as a whole. The group coordinator then adjusts its local volume and initiates network transactions with the other players in the group to adjust their volumes accordingly. While players adjust their volumes, the group coordinator may generate one or more groupVolume events.   Since this command can generate additional network transactions and events, your app should optimize how often it is invoked to maintain a good user experience without flooding the network, such as when implementing a touch screen volume slider that controls a group of five players.
         * @summary setVolume
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetVolume(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = GroupVolumeApiFetchParamCreator(configuration).groupVolumeSetVolume(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the groupVolume namespace.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSubscribe(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = GroupVolumeApiFetchParamCreator(configuration).groupVolumeSubscribe(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the groupVolume namespace.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeUnsubscribe(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = GroupVolumeApiFetchParamCreator(configuration).groupVolumeUnsubscribe(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * GroupVolumeApi - factory interface
 * @export
 */
export const GroupVolumeApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use the getVolume command in the groupVolume namespace to get the volume and mute state of a group.
         * @summary getVolume
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeGetVolume(groupId, xSonosCorrId, options) {
            return GroupVolumeApiFp(configuration).groupVolumeGetVolume(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setMute command in the groupVolume namespace to mute and unmute the group.   The group’s mute state is independent of its group volume. For example a group’s mute state can be true although its group volume is still 20, and the user will not hear any audio coming from the group of players. Individual player volume values are stored by each player in the group while muted, which allows the group to retain the relative volume of players in the group. That is important information to preserve, since users may have carefully configured the individual player volumes in the group with a Sonos controller. Your app should not mute the group by calling setVolume with zero volume, otherwise the relative volume of players in the group will be lost.   If some players in the group are muted and others are not, calling setMute to mute the group will mute all the players in the group. The individual player’s mute state prior to calling setMute is not preserved.   To get the current group’s mute state, use the getVolume command or subscribe to the groupVolume event.
         * @summary setMute
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetMuteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetMute(groupId, body, xSonosCorrId, options) {
            return GroupVolumeApiFp(configuration).groupVolumeSetMute(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setRelativeVolume command in the groupVolume namespace to increase or decrease group volume, and unmute the group if muted.   Your app can use setRelativeVolume instead of setVolume when the user intent is to increase or decrease the group volume, but not to set the final volume to a particular value. For example, your app should use setRelativeVolume when the user presses hard volume plus/minus buttons on a mobile device.   The group will automatically limit the final volume set within the valid range, so your app does not need to worry about that.   See setVolume for more details on how group volume works.
         * @summary setRelativeVolume
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetRelativeVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetRelativeVolume(groupId, body, xSonosCorrId, options) {
            return GroupVolumeApiFp(configuration).groupVolumeSetRelativeVolume(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setVolume command in the groupVolume namespace to set group volume to a specific level and unmute the group if muted.   When your app sets the group volume, the group coordinator calculates the required changes to the volume level for each player in the group so that the result is the average volume level of the group as a whole. The group coordinator then adjusts its local volume and initiates network transactions with the other players in the group to adjust their volumes accordingly. While players adjust their volumes, the group coordinator may generate one or more groupVolume events.   Since this command can generate additional network transactions and events, your app should optimize how often it is invoked to maintain a good user experience without flooding the network, such as when implementing a touch screen volume slider that controls a group of five players.
         * @summary setVolume
         * @param {string} groupId Target groupId
         * @param {GroupVolumeSetVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSetVolume(groupId, body, xSonosCorrId, options) {
            return GroupVolumeApiFp(configuration).groupVolumeSetVolume(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the groupVolume namespace.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeSubscribe(groupId, xSonosCorrId, options) {
            return GroupVolumeApiFp(configuration).groupVolumeSubscribe(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the groupVolume namespace.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupVolumeUnsubscribe(groupId, xSonosCorrId, options) {
            return GroupVolumeApiFp(configuration).groupVolumeUnsubscribe(groupId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * GroupVolumeApi - object-oriented interface
 * @export
 * @class GroupVolumeApi
 * @extends {BaseAPI}
 */
export class GroupVolumeApi extends BaseAPI {
    /**
     * Use the getVolume command in the groupVolume namespace to get the volume and mute state of a group.
     * @summary getVolume
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupVolumeApi
     */
    groupVolumeGetVolume(groupId, xSonosCorrId, options) {
        return GroupVolumeApiFp(this.configuration).groupVolumeGetVolume(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setMute command in the groupVolume namespace to mute and unmute the group.   The group’s mute state is independent of its group volume. For example a group’s mute state can be true although its group volume is still 20, and the user will not hear any audio coming from the group of players. Individual player volume values are stored by each player in the group while muted, which allows the group to retain the relative volume of players in the group. That is important information to preserve, since users may have carefully configured the individual player volumes in the group with a Sonos controller. Your app should not mute the group by calling setVolume with zero volume, otherwise the relative volume of players in the group will be lost.   If some players in the group are muted and others are not, calling setMute to mute the group will mute all the players in the group. The individual player’s mute state prior to calling setMute is not preserved.   To get the current group’s mute state, use the getVolume command or subscribe to the groupVolume event.
     * @summary setMute
     * @param {string} groupId Target groupId
     * @param {GroupVolumeSetMuteBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupVolumeApi
     */
    groupVolumeSetMute(groupId, body, xSonosCorrId, options) {
        return GroupVolumeApiFp(this.configuration).groupVolumeSetMute(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setRelativeVolume command in the groupVolume namespace to increase or decrease group volume, and unmute the group if muted.   Your app can use setRelativeVolume instead of setVolume when the user intent is to increase or decrease the group volume, but not to set the final volume to a particular value. For example, your app should use setRelativeVolume when the user presses hard volume plus/minus buttons on a mobile device.   The group will automatically limit the final volume set within the valid range, so your app does not need to worry about that.   See setVolume for more details on how group volume works.
     * @summary setRelativeVolume
     * @param {string} groupId Target groupId
     * @param {GroupVolumeSetRelativeVolumeBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupVolumeApi
     */
    groupVolumeSetRelativeVolume(groupId, body, xSonosCorrId, options) {
        return GroupVolumeApiFp(this.configuration).groupVolumeSetRelativeVolume(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setVolume command in the groupVolume namespace to set group volume to a specific level and unmute the group if muted.   When your app sets the group volume, the group coordinator calculates the required changes to the volume level for each player in the group so that the result is the average volume level of the group as a whole. The group coordinator then adjusts its local volume and initiates network transactions with the other players in the group to adjust their volumes accordingly. While players adjust their volumes, the group coordinator may generate one or more groupVolume events.   Since this command can generate additional network transactions and events, your app should optimize how often it is invoked to maintain a good user experience without flooding the network, such as when implementing a touch screen volume slider that controls a group of five players.
     * @summary setVolume
     * @param {string} groupId Target groupId
     * @param {GroupVolumeSetVolumeBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupVolumeApi
     */
    groupVolumeSetVolume(groupId, body, xSonosCorrId, options) {
        return GroupVolumeApiFp(this.configuration).groupVolumeSetVolume(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the groupVolume namespace.
     * @summary subscribe
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupVolumeApi
     */
    groupVolumeSubscribe(groupId, xSonosCorrId, options) {
        return GroupVolumeApiFp(this.configuration).groupVolumeSubscribe(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the groupVolume namespace.
     * @summary unsubscribe
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupVolumeApi
     */
    groupVolumeUnsubscribe(groupId, xSonosCorrId, options) {
        return GroupVolumeApiFp(this.configuration).groupVolumeUnsubscribe(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * GroupsApi - fetch parameter creator
 * @export
 */
export const GroupsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use the createGroup command in the groups namespace to create a new group from a list  of players. The player returns a group object with the group ID. This may be an existing  group ID if an existing group is a subset of the new group. In this case, Sonos may  build the new group by adding new players to the existing group.
         * @summary createGroup
         * @param {string} householdId Target householdId
         * @param {GroupsCreateGroupBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsCreateGroup(householdId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling groupsCreateGroup.');
            }
            const localVarPath = `/households/{householdId}/groups/createGroup`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("GroupsCreateGroupBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the getGroups command in the groups namespace to get information about groups  and players in a household.
         * @summary getGroups
         * @param {string} householdId Target householdId
         * @param {boolean} [includeDeviceInfo] The response will include the deviceInfo for each player object when this  argument is present and true.
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsGetGroups(householdId, includeDeviceInfo, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling groupsGetGroups.');
            }
            const localVarPath = `/households/{householdId}/groups`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (includeDeviceInfo !== undefined) {
                localVarQueryParameter['includeDeviceInfo'] = includeDeviceInfo;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the modifyGroupMembers command in the groups namespace to add players to and remove  players from a group. In response to this command, Sonos first adds players to the group,  then removes players from the group.
         * @summary modifyGroupMembers
         * @param {string} groupId Target groupId
         * @param {GroupsModifyGroupMembersBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsModifyGroupMembers(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupsModifyGroupMembers.');
            }
            const localVarPath = `/groups/{groupId}/groups/modifyGroupMembers`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("GroupsModifyGroupMembersBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setGroupMembers command in the groups namespace to replace the players in an  existing group with a new set.
         * @summary setGroupMembers
         * @param {string} groupId Target groupId
         * @param {GroupsSetGroupMembersBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsSetGroupMembers(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling groupsSetGroupMembers.');
            }
            const localVarPath = `/groups/{groupId}/groups/setGroupMembers`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("GroupsSetGroupMembersBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the groups namespace.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsSubscribe(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling groupsSubscribe.');
            }
            const localVarPath = `/households/{householdId}/groups/subscription`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the groups namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsUnsubscribe(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling groupsUnsubscribe.');
            }
            const localVarPath = `/households/{householdId}/groups/subscription`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function (configuration) {
    return {
        /**
         * Use the createGroup command in the groups namespace to create a new group from a list  of players. The player returns a group object with the group ID. This may be an existing  group ID if an existing group is a subset of the new group. In this case, Sonos may  build the new group by adding new players to the existing group.
         * @summary createGroup
         * @param {string} householdId Target householdId
         * @param {GroupsCreateGroupBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsCreateGroup(householdId, body, xSonosCorrId, options) {
            const localVarFetchArgs = GroupsApiFetchParamCreator(configuration).groupsCreateGroup(householdId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the getGroups command in the groups namespace to get information about groups  and players in a household.
         * @summary getGroups
         * @param {string} householdId Target householdId
         * @param {boolean} [includeDeviceInfo] The response will include the deviceInfo for each player object when this  argument is present and true.
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsGetGroups(householdId, includeDeviceInfo, xSonosCorrId, options) {
            const localVarFetchArgs = GroupsApiFetchParamCreator(configuration).groupsGetGroups(householdId, includeDeviceInfo, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the modifyGroupMembers command in the groups namespace to add players to and remove  players from a group. In response to this command, Sonos first adds players to the group,  then removes players from the group.
         * @summary modifyGroupMembers
         * @param {string} groupId Target groupId
         * @param {GroupsModifyGroupMembersBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsModifyGroupMembers(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = GroupsApiFetchParamCreator(configuration).groupsModifyGroupMembers(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setGroupMembers command in the groups namespace to replace the players in an  existing group with a new set.
         * @summary setGroupMembers
         * @param {string} groupId Target groupId
         * @param {GroupsSetGroupMembersBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsSetGroupMembers(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = GroupsApiFetchParamCreator(configuration).groupsSetGroupMembers(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the groups namespace.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsSubscribe(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = GroupsApiFetchParamCreator(configuration).groupsSubscribe(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the groups namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsUnsubscribe(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = GroupsApiFetchParamCreator(configuration).groupsUnsubscribe(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use the createGroup command in the groups namespace to create a new group from a list  of players. The player returns a group object with the group ID. This may be an existing  group ID if an existing group is a subset of the new group. In this case, Sonos may  build the new group by adding new players to the existing group.
         * @summary createGroup
         * @param {string} householdId Target householdId
         * @param {GroupsCreateGroupBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsCreateGroup(householdId, body, xSonosCorrId, options) {
            return GroupsApiFp(configuration).groupsCreateGroup(householdId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the getGroups command in the groups namespace to get information about groups  and players in a household.
         * @summary getGroups
         * @param {string} householdId Target householdId
         * @param {boolean} [includeDeviceInfo] The response will include the deviceInfo for each player object when this  argument is present and true.
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsGetGroups(householdId, includeDeviceInfo, xSonosCorrId, options) {
            return GroupsApiFp(configuration).groupsGetGroups(householdId, includeDeviceInfo, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the modifyGroupMembers command in the groups namespace to add players to and remove  players from a group. In response to this command, Sonos first adds players to the group,  then removes players from the group.
         * @summary modifyGroupMembers
         * @param {string} groupId Target groupId
         * @param {GroupsModifyGroupMembersBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsModifyGroupMembers(groupId, body, xSonosCorrId, options) {
            return GroupsApiFp(configuration).groupsModifyGroupMembers(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setGroupMembers command in the groups namespace to replace the players in an  existing group with a new set.
         * @summary setGroupMembers
         * @param {string} groupId Target groupId
         * @param {GroupsSetGroupMembersBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsSetGroupMembers(groupId, body, xSonosCorrId, options) {
            return GroupsApiFp(configuration).groupsSetGroupMembers(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the groups namespace.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsSubscribe(householdId, xSonosCorrId, options) {
            return GroupsApiFp(configuration).groupsSubscribe(householdId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the groups namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsUnsubscribe(householdId, xSonosCorrId, options) {
            return GroupsApiFp(configuration).groupsUnsubscribe(householdId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * Use the createGroup command in the groups namespace to create a new group from a list  of players. The player returns a group object with the group ID. This may be an existing  group ID if an existing group is a subset of the new group. In this case, Sonos may  build the new group by adding new players to the existing group.
     * @summary createGroup
     * @param {string} householdId Target householdId
     * @param {GroupsCreateGroupBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    groupsCreateGroup(householdId, body, xSonosCorrId, options) {
        return GroupsApiFp(this.configuration).groupsCreateGroup(householdId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the getGroups command in the groups namespace to get information about groups  and players in a household.
     * @summary getGroups
     * @param {string} householdId Target householdId
     * @param {boolean} [includeDeviceInfo] The response will include the deviceInfo for each player object when this  argument is present and true.
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    groupsGetGroups(householdId, includeDeviceInfo, xSonosCorrId, options) {
        return GroupsApiFp(this.configuration).groupsGetGroups(householdId, includeDeviceInfo, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the modifyGroupMembers command in the groups namespace to add players to and remove  players from a group. In response to this command, Sonos first adds players to the group,  then removes players from the group.
     * @summary modifyGroupMembers
     * @param {string} groupId Target groupId
     * @param {GroupsModifyGroupMembersBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    groupsModifyGroupMembers(groupId, body, xSonosCorrId, options) {
        return GroupsApiFp(this.configuration).groupsModifyGroupMembers(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setGroupMembers command in the groups namespace to replace the players in an  existing group with a new set.
     * @summary setGroupMembers
     * @param {string} groupId Target groupId
     * @param {GroupsSetGroupMembersBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    groupsSetGroupMembers(groupId, body, xSonosCorrId, options) {
        return GroupsApiFp(this.configuration).groupsSetGroupMembers(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the groups namespace.
     * @summary subscribe
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    groupsSubscribe(householdId, xSonosCorrId, options) {
        return GroupsApiFp(this.configuration).groupsSubscribe(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the groups namespace.
     * @summary unsubscribe
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    groupsUnsubscribe(householdId, xSonosCorrId, options) {
        return GroupsApiFp(this.configuration).groupsUnsubscribe(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * HouseholdsApi - fetch parameter creator
 * @export
 */
export const HouseholdsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * The getHouseholds command requests information on the households that can be acted upon,  based upon the access token used when calling this API. There are no parameters for this command.   If successful, Sonos responds with an array of household objects.
         * @summary getHouseholds
         * @param {boolean} [connectedOnly] Lookup all the households, connected or disconnected, when set to false.
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        householdsGetHouseholds(connectedOnly, xSonosCorrId, options = {}) {
            const localVarPath = `/households`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (connectedOnly !== undefined) {
                localVarQueryParameter['connectedOnly'] = connectedOnly;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the user-configured name for the muse household.
         * @summary setName
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        householdsSetName(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling householdsSetName.');
            }
            const localVarPath = `/households/{householdId}/households/name`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * HouseholdsApi - functional programming interface
 * @export
 */
export const HouseholdsApiFp = function (configuration) {
    return {
        /**
         * The getHouseholds command requests information on the households that can be acted upon,  based upon the access token used when calling this API. There are no parameters for this command.   If successful, Sonos responds with an array of household objects.
         * @summary getHouseholds
         * @param {boolean} [connectedOnly] Lookup all the households, connected or disconnected, when set to false.
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        householdsGetHouseholds(connectedOnly, xSonosCorrId, options) {
            const localVarFetchArgs = HouseholdsApiFetchParamCreator(configuration).householdsGetHouseholds(connectedOnly, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Set the user-configured name for the muse household.
         * @summary setName
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        householdsSetName(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = HouseholdsApiFetchParamCreator(configuration).householdsSetName(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * HouseholdsApi - factory interface
 * @export
 */
export const HouseholdsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * The getHouseholds command requests information on the households that can be acted upon,  based upon the access token used when calling this API. There are no parameters for this command.   If successful, Sonos responds with an array of household objects.
         * @summary getHouseholds
         * @param {boolean} [connectedOnly] Lookup all the households, connected or disconnected, when set to false.
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        householdsGetHouseholds(connectedOnly, xSonosCorrId, options) {
            return HouseholdsApiFp(configuration).householdsGetHouseholds(connectedOnly, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Set the user-configured name for the muse household.
         * @summary setName
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        householdsSetName(householdId, xSonosCorrId, options) {
            return HouseholdsApiFp(configuration).householdsSetName(householdId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * HouseholdsApi - object-oriented interface
 * @export
 * @class HouseholdsApi
 * @extends {BaseAPI}
 */
export class HouseholdsApi extends BaseAPI {
    /**
     * The getHouseholds command requests information on the households that can be acted upon,  based upon the access token used when calling this API. There are no parameters for this command.   If successful, Sonos responds with an array of household objects.
     * @summary getHouseholds
     * @param {boolean} [connectedOnly] Lookup all the households, connected or disconnected, when set to false.
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdsApi
     */
    householdsGetHouseholds(connectedOnly, xSonosCorrId, options) {
        return HouseholdsApiFp(this.configuration).householdsGetHouseholds(connectedOnly, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Set the user-configured name for the muse household.
     * @summary setName
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdsApi
     */
    householdsSetName(householdId, xSonosCorrId, options) {
        return HouseholdsApiFp(this.configuration).householdsSetName(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * MusicServiceAccountsApi - fetch parameter creator
 * @export
 */
export const MusicServiceAccountsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use the match command in the musicServiceAccounts namespace to get the account ID of a music service user account from the player.   All players in the household return the same accounts. Changes made to a player are automatically replicated throughout the household.
         * @summary match
         * @param {string} householdId Target householdId
         * @param {MusicServiceAccountsMatchBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        musicServiceAccountsMatch(householdId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling musicServiceAccountsMatch.');
            }
            const localVarPath = `/households/{householdId}/musicServiceAccounts/match`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("MusicServiceAccountsMatchBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * MusicServiceAccountsApi - functional programming interface
 * @export
 */
export const MusicServiceAccountsApiFp = function (configuration) {
    return {
        /**
         * Use the match command in the musicServiceAccounts namespace to get the account ID of a music service user account from the player.   All players in the household return the same accounts. Changes made to a player are automatically replicated throughout the household.
         * @summary match
         * @param {string} householdId Target householdId
         * @param {MusicServiceAccountsMatchBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        musicServiceAccountsMatch(householdId, body, xSonosCorrId, options) {
            const localVarFetchArgs = MusicServiceAccountsApiFetchParamCreator(configuration).musicServiceAccountsMatch(householdId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * MusicServiceAccountsApi - factory interface
 * @export
 */
export const MusicServiceAccountsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use the match command in the musicServiceAccounts namespace to get the account ID of a music service user account from the player.   All players in the household return the same accounts. Changes made to a player are automatically replicated throughout the household.
         * @summary match
         * @param {string} householdId Target householdId
         * @param {MusicServiceAccountsMatchBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        musicServiceAccountsMatch(householdId, body, xSonosCorrId, options) {
            return MusicServiceAccountsApiFp(configuration).musicServiceAccountsMatch(householdId, body, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * MusicServiceAccountsApi - object-oriented interface
 * @export
 * @class MusicServiceAccountsApi
 * @extends {BaseAPI}
 */
export class MusicServiceAccountsApi extends BaseAPI {
    /**
     * Use the match command in the musicServiceAccounts namespace to get the account ID of a music service user account from the player.   All players in the household return the same accounts. Changes made to a player are automatically replicated throughout the household.
     * @summary match
     * @param {string} householdId Target householdId
     * @param {MusicServiceAccountsMatchBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicServiceAccountsApi
     */
    musicServiceAccountsMatch(householdId, body, xSonosCorrId, options) {
        return MusicServiceAccountsApiFp(this.configuration).musicServiceAccountsMatch(householdId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * PlaybackApi - fetch parameter creator
 * @export
 */
export const PlaybackApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use the getPlaybackStatus command in the playback namespace get the current playback status (transport state) for the group, such as PLAYBACK_STATE_IDLE, PLAYBACK_STATE_BUFFERING, or PLAYBACK_STATE_PLAYING. See the playbackStatus object for details.
         * @summary getPlaybackStatus
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackGetPlaybackStatus(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackGetPlaybackStatus.');
            }
            const localVarPath = `/groups/{groupId}/playback`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the loadLineIn command in the playback namespace to change the current group source to the line-in source of a specified player. This can be be any player in the household that supports line-in. See Using Line-In on Sonos on the Sonos Support site for more details about the line-in capabilities of our players.   You can tell whether a player has line-in capabilities with the LINE_IN value in the capabilities object. See the groups object for details.   The player will switch away from the line-in source when the hardware detects that the user has physically unplugged the line-in cable.
         * @summary loadLineIn
         * @param {string} groupId Target groupId
         * @param {PlaybackLoadLineInBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackLoadLineIn(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackLoadLineIn.');
            }
            const localVarPath = `/groups/{groupId}/playback/lineIn`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackLoadLineInBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the pause command in the playback namespace to pause group playback.   After a successful pause command, if your app is subscribed to the playback namespace, it will receive a playbackStatus event with either PLAYBACK_STATE_PAUSED or PLAYBACK_STATE_IDLE, depending on the audio source loaded on the group.
         * @summary pause
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackPause(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackPause.');
            }
            const localVarPath = `/groups/{groupId}/playback/pause`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the play command in the playback namespace to initiate group playback.   After a successful play command, if your app is subscribed to the playback namespace, it may first receive a playbackStatus event with PLAYBACK_STATE_BUFFERING before another event with PLAYBACK_STATE_PLAYING. Sonos players will buffer the audio ahead of time when possible, in order to provide the shortest time to play for the user, and to prevent any audio gaps between tracks.
         * @summary play
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackPlay(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackPlay.');
            }
            const localVarPath = `/groups/{groupId}/playback/play`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the seek command in the playback namespace to go to a specific position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns an ERROR_INVALID_OBJECT_ID error.
         * @summary seek
         * @param {string} groupId Target groupId
         * @param {PlaybackSeekBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSeek(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSeek.');
            }
            const localVarPath = `/groups/{groupId}/playback/seek`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSeekBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the seekRelative command in the playback namespace to seek to the a relative position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns ERROR_INVALID_OBJECT_ID.
         * @summary seekRelative
         * @param {string} groupId Target groupId
         * @param {PlaybackSeekRelativeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSeekRelative(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSeekRelative.');
            }
            const localVarPath = `/groups/{groupId}/playback/seekRelative`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSeekRelativeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setPlayModes command in the playback namespace to change group play modes, such as repeat, repeat one track, shuffle, and crossfade. See Play Modes for details.   Configure a play mode in the playModes array by adding a “true” or “false” value for the mode, or omit a play mode to leave it unchanged.
         * @summary setPlayModes
         * @param {string} groupId Target groupId
         * @param {PlaybackSetPlayModesBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSetPlayModes(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSetPlayModes.');
            }
            const localVarPath = `/groups/{groupId}/playback/playMode`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSetPlayModesBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the skipBack command in the playback namespace to rewind to beginning of track if it has been playing long enough(3 seconds), otherwise skip to previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipBack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED if it tries to skip to previous track, but audio will continue playing.
         * @summary skipBack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipBack(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSkipBack.');
            }
            const localVarPath = `/groups/{groupId}/playback/skipBack`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the skipToNextTrack command in the playback namespace to skip to the next track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no next track to skip to. If you send a skipToNextTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but the audio will continue playing.
         * @summary skipToNextTrack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipToNextTrack(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSkipToNextTrack.');
            }
            const localVarPath = `/groups/{groupId}/playback/skipToNextTrack`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the skipToPreviousTrack command in the playback namespace to skip to the previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipToPreviousTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but audio will continue playing.
         * @summary skipToPreviousTrack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipToPreviousTrack(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSkipToPreviousTrack.');
            }
            const localVarPath = `/groups/{groupId}/playback/skipToPreviousTrack`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the playback namespace.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSubscribe(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSubscribe.');
            }
            const localVarPath = `/groups/{groupId}/playback/subscription`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the togglePlayPause command in the playback namespace to toggle the playback state on the given group, if possible. Upon completion of the command, the player sends playbackStatus events to all subscribers. When toggling from pause to play, this functions similar to the play command. When transitioning from play to pause, it functions like the pause command. See those commands for more details about the functionality of those states.
         * @summary togglePlayPause
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackTogglePlayPause(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackTogglePlayPause.');
            }
            const localVarPath = `/groups/{groupId}/playback/togglePlayPause`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the playback namespace.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackUnsubscribe(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackUnsubscribe.');
            }
            const localVarPath = `/groups/{groupId}/playback/subscription`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PlaybackApi - functional programming interface
 * @export
 */
export const PlaybackApiFp = function (configuration) {
    return {
        /**
         * Use the getPlaybackStatus command in the playback namespace get the current playback status (transport state) for the group, such as PLAYBACK_STATE_IDLE, PLAYBACK_STATE_BUFFERING, or PLAYBACK_STATE_PLAYING. See the playbackStatus object for details.
         * @summary getPlaybackStatus
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackGetPlaybackStatus(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackGetPlaybackStatus(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the loadLineIn command in the playback namespace to change the current group source to the line-in source of a specified player. This can be be any player in the household that supports line-in. See Using Line-In on Sonos on the Sonos Support site for more details about the line-in capabilities of our players.   You can tell whether a player has line-in capabilities with the LINE_IN value in the capabilities object. See the groups object for details.   The player will switch away from the line-in source when the hardware detects that the user has physically unplugged the line-in cable.
         * @summary loadLineIn
         * @param {string} groupId Target groupId
         * @param {PlaybackLoadLineInBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackLoadLineIn(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackLoadLineIn(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the pause command in the playback namespace to pause group playback.   After a successful pause command, if your app is subscribed to the playback namespace, it will receive a playbackStatus event with either PLAYBACK_STATE_PAUSED or PLAYBACK_STATE_IDLE, depending on the audio source loaded on the group.
         * @summary pause
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackPause(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackPause(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the play command in the playback namespace to initiate group playback.   After a successful play command, if your app is subscribed to the playback namespace, it may first receive a playbackStatus event with PLAYBACK_STATE_BUFFERING before another event with PLAYBACK_STATE_PLAYING. Sonos players will buffer the audio ahead of time when possible, in order to provide the shortest time to play for the user, and to prevent any audio gaps between tracks.
         * @summary play
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackPlay(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackPlay(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the seek command in the playback namespace to go to a specific position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns an ERROR_INVALID_OBJECT_ID error.
         * @summary seek
         * @param {string} groupId Target groupId
         * @param {PlaybackSeekBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSeek(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackSeek(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the seekRelative command in the playback namespace to seek to the a relative position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns ERROR_INVALID_OBJECT_ID.
         * @summary seekRelative
         * @param {string} groupId Target groupId
         * @param {PlaybackSeekRelativeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSeekRelative(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackSeekRelative(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setPlayModes command in the playback namespace to change group play modes, such as repeat, repeat one track, shuffle, and crossfade. See Play Modes for details.   Configure a play mode in the playModes array by adding a “true” or “false” value for the mode, or omit a play mode to leave it unchanged.
         * @summary setPlayModes
         * @param {string} groupId Target groupId
         * @param {PlaybackSetPlayModesBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSetPlayModes(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackSetPlayModes(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the skipBack command in the playback namespace to rewind to beginning of track if it has been playing long enough(3 seconds), otherwise skip to previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipBack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED if it tries to skip to previous track, but audio will continue playing.
         * @summary skipBack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipBack(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackSkipBack(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the skipToNextTrack command in the playback namespace to skip to the next track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no next track to skip to. If you send a skipToNextTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but the audio will continue playing.
         * @summary skipToNextTrack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipToNextTrack(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackSkipToNextTrack(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the skipToPreviousTrack command in the playback namespace to skip to the previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipToPreviousTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but audio will continue playing.
         * @summary skipToPreviousTrack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipToPreviousTrack(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackSkipToPreviousTrack(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the playback namespace.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSubscribe(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackSubscribe(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the togglePlayPause command in the playback namespace to toggle the playback state on the given group, if possible. Upon completion of the command, the player sends playbackStatus events to all subscribers. When toggling from pause to play, this functions similar to the play command. When transitioning from play to pause, it functions like the pause command. See those commands for more details about the functionality of those states.
         * @summary togglePlayPause
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackTogglePlayPause(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackTogglePlayPause(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the playback namespace.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackUnsubscribe(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackApiFetchParamCreator(configuration).playbackUnsubscribe(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * PlaybackApi - factory interface
 * @export
 */
export const PlaybackApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use the getPlaybackStatus command in the playback namespace get the current playback status (transport state) for the group, such as PLAYBACK_STATE_IDLE, PLAYBACK_STATE_BUFFERING, or PLAYBACK_STATE_PLAYING. See the playbackStatus object for details.
         * @summary getPlaybackStatus
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackGetPlaybackStatus(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackGetPlaybackStatus(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the loadLineIn command in the playback namespace to change the current group source to the line-in source of a specified player. This can be be any player in the household that supports line-in. See Using Line-In on Sonos on the Sonos Support site for more details about the line-in capabilities of our players.   You can tell whether a player has line-in capabilities with the LINE_IN value in the capabilities object. See the groups object for details.   The player will switch away from the line-in source when the hardware detects that the user has physically unplugged the line-in cable.
         * @summary loadLineIn
         * @param {string} groupId Target groupId
         * @param {PlaybackLoadLineInBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackLoadLineIn(groupId, body, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackLoadLineIn(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the pause command in the playback namespace to pause group playback.   After a successful pause command, if your app is subscribed to the playback namespace, it will receive a playbackStatus event with either PLAYBACK_STATE_PAUSED or PLAYBACK_STATE_IDLE, depending on the audio source loaded on the group.
         * @summary pause
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackPause(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackPause(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the play command in the playback namespace to initiate group playback.   After a successful play command, if your app is subscribed to the playback namespace, it may first receive a playbackStatus event with PLAYBACK_STATE_BUFFERING before another event with PLAYBACK_STATE_PLAYING. Sonos players will buffer the audio ahead of time when possible, in order to provide the shortest time to play for the user, and to prevent any audio gaps between tracks.
         * @summary play
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackPlay(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackPlay(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the seek command in the playback namespace to go to a specific position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns an ERROR_INVALID_OBJECT_ID error.
         * @summary seek
         * @param {string} groupId Target groupId
         * @param {PlaybackSeekBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSeek(groupId, body, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackSeek(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the seekRelative command in the playback namespace to seek to the a relative position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns ERROR_INVALID_OBJECT_ID.
         * @summary seekRelative
         * @param {string} groupId Target groupId
         * @param {PlaybackSeekRelativeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSeekRelative(groupId, body, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackSeekRelative(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setPlayModes command in the playback namespace to change group play modes, such as repeat, repeat one track, shuffle, and crossfade. See Play Modes for details.   Configure a play mode in the playModes array by adding a “true” or “false” value for the mode, or omit a play mode to leave it unchanged.
         * @summary setPlayModes
         * @param {string} groupId Target groupId
         * @param {PlaybackSetPlayModesBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSetPlayModes(groupId, body, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackSetPlayModes(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the skipBack command in the playback namespace to rewind to beginning of track if it has been playing long enough(3 seconds), otherwise skip to previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipBack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED if it tries to skip to previous track, but audio will continue playing.
         * @summary skipBack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipBack(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackSkipBack(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the skipToNextTrack command in the playback namespace to skip to the next track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no next track to skip to. If you send a skipToNextTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but the audio will continue playing.
         * @summary skipToNextTrack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipToNextTrack(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackSkipToNextTrack(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the skipToPreviousTrack command in the playback namespace to skip to the previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipToPreviousTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but audio will continue playing.
         * @summary skipToPreviousTrack
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSkipToPreviousTrack(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackSkipToPreviousTrack(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the playback namespace.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSubscribe(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackSubscribe(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the togglePlayPause command in the playback namespace to toggle the playback state on the given group, if possible. Upon completion of the command, the player sends playbackStatus events to all subscribers. When toggling from pause to play, this functions similar to the play command. When transitioning from play to pause, it functions like the pause command. See those commands for more details about the functionality of those states.
         * @summary togglePlayPause
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackTogglePlayPause(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackTogglePlayPause(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the playback namespace.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackUnsubscribe(groupId, xSonosCorrId, options) {
            return PlaybackApiFp(configuration).playbackUnsubscribe(groupId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * PlaybackApi - object-oriented interface
 * @export
 * @class PlaybackApi
 * @extends {BaseAPI}
 */
export class PlaybackApi extends BaseAPI {
    /**
     * Use the getPlaybackStatus command in the playback namespace get the current playback status (transport state) for the group, such as PLAYBACK_STATE_IDLE, PLAYBACK_STATE_BUFFERING, or PLAYBACK_STATE_PLAYING. See the playbackStatus object for details.
     * @summary getPlaybackStatus
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackGetPlaybackStatus(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackGetPlaybackStatus(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the loadLineIn command in the playback namespace to change the current group source to the line-in source of a specified player. This can be be any player in the household that supports line-in. See Using Line-In on Sonos on the Sonos Support site for more details about the line-in capabilities of our players.   You can tell whether a player has line-in capabilities with the LINE_IN value in the capabilities object. See the groups object for details.   The player will switch away from the line-in source when the hardware detects that the user has physically unplugged the line-in cable.
     * @summary loadLineIn
     * @param {string} groupId Target groupId
     * @param {PlaybackLoadLineInBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackLoadLineIn(groupId, body, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackLoadLineIn(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the pause command in the playback namespace to pause group playback.   After a successful pause command, if your app is subscribed to the playback namespace, it will receive a playbackStatus event with either PLAYBACK_STATE_PAUSED or PLAYBACK_STATE_IDLE, depending on the audio source loaded on the group.
     * @summary pause
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackPause(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackPause(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the play command in the playback namespace to initiate group playback.   After a successful play command, if your app is subscribed to the playback namespace, it may first receive a playbackStatus event with PLAYBACK_STATE_BUFFERING before another event with PLAYBACK_STATE_PLAYING. Sonos players will buffer the audio ahead of time when possible, in order to provide the shortest time to play for the user, and to prevent any audio gaps between tracks.
     * @summary play
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackPlay(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackPlay(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the seek command in the playback namespace to go to a specific position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns an ERROR_INVALID_OBJECT_ID error.
     * @summary seek
     * @param {string} groupId Target groupId
     * @param {PlaybackSeekBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackSeek(groupId, body, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackSeek(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the seekRelative command in the playback namespace to seek to the a relative position in the current track. Optionally add the cloud queue itemId to target a specific track.   Use itemId to ensure the group seeks on the current item. If the current item’s itemId does not match the one provided, the player returns ERROR_INVALID_OBJECT_ID.
     * @summary seekRelative
     * @param {string} groupId Target groupId
     * @param {PlaybackSeekRelativeBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackSeekRelative(groupId, body, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackSeekRelative(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setPlayModes command in the playback namespace to change group play modes, such as repeat, repeat one track, shuffle, and crossfade. See Play Modes for details.   Configure a play mode in the playModes array by adding a “true” or “false” value for the mode, or omit a play mode to leave it unchanged.
     * @summary setPlayModes
     * @param {string} groupId Target groupId
     * @param {PlaybackSetPlayModesBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackSetPlayModes(groupId, body, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackSetPlayModes(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the skipBack command in the playback namespace to rewind to beginning of track if it has been playing long enough(3 seconds), otherwise skip to previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipBack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED if it tries to skip to previous track, but audio will continue playing.
     * @summary skipBack
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackSkipBack(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackSkipBack(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the skipToNextTrack command in the playback namespace to skip to the next track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no next track to skip to. If you send a skipToNextTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but the audio will continue playing.
     * @summary skipToNextTrack
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackSkipToNextTrack(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackSkipToNextTrack(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the skipToPreviousTrack command in the playback namespace to skip to the previous track.   Not all audio sources allow multiple tracks. For example, when a group is streaming an Internet radio station, there is no previous track to skip to. If you send a skipToPreviousTrack command when the audio source does not support multiple tracks, your app will receive an ERROR_PLAYBACK_FAILED, but audio will continue playing.
     * @summary skipToPreviousTrack
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackSkipToPreviousTrack(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackSkipToPreviousTrack(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the playback namespace.
     * @summary subscribe
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackSubscribe(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackSubscribe(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the togglePlayPause command in the playback namespace to toggle the playback state on the given group, if possible. Upon completion of the command, the player sends playbackStatus events to all subscribers. When toggling from pause to play, this functions similar to the play command. When transitioning from play to pause, it functions like the pause command. See those commands for more details about the functionality of those states.
     * @summary togglePlayPause
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackTogglePlayPause(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackTogglePlayPause(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the playback namespace.
     * @summary unsubscribe
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackApi
     */
    playbackUnsubscribe(groupId, xSonosCorrId, options) {
        return PlaybackApiFp(this.configuration).playbackUnsubscribe(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * PlaybackMetadataApi - fetch parameter creator
 * @export
 */
export const PlaybackMetadataApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use this command to poll for metadata changes. This command returns a metadataStatus object for the target group.
         * @summary getMetadataStatus
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataGetMetadataStatus(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackMetadataGetMetadataStatus.');
            }
            const localVarPath = `/groups/{groupId}/playbackMetadata`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful and sends a metadataStatus event only to the subscriber.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataSubscribe(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackMetadataSubscribe.');
            }
            const localVarPath = `/groups/{groupId}/playbackMetadata/subscription`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataUnsubscribe(groupId, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackMetadataUnsubscribe.');
            }
            const localVarPath = `/groups/{groupId}/playbackMetadata/subscription`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PlaybackMetadataApi - functional programming interface
 * @export
 */
export const PlaybackMetadataApiFp = function (configuration) {
    return {
        /**
         * Use this command to poll for metadata changes. This command returns a metadataStatus object for the target group.
         * @summary getMetadataStatus
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataGetMetadataStatus(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackMetadataApiFetchParamCreator(configuration).playbackMetadataGetMetadataStatus(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful and sends a metadataStatus event only to the subscriber.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataSubscribe(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackMetadataApiFetchParamCreator(configuration).playbackMetadataSubscribe(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataUnsubscribe(groupId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackMetadataApiFetchParamCreator(configuration).playbackMetadataUnsubscribe(groupId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * PlaybackMetadataApi - factory interface
 * @export
 */
export const PlaybackMetadataApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use this command to poll for metadata changes. This command returns a metadataStatus object for the target group.
         * @summary getMetadataStatus
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataGetMetadataStatus(groupId, xSonosCorrId, options) {
            return PlaybackMetadataApiFp(configuration).playbackMetadataGetMetadataStatus(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful and sends a metadataStatus event only to the subscriber.
         * @summary subscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataSubscribe(groupId, xSonosCorrId, options) {
            return PlaybackMetadataApiFp(configuration).playbackMetadataSubscribe(groupId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful.
         * @summary unsubscribe
         * @param {string} groupId Target groupId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackMetadataUnsubscribe(groupId, xSonosCorrId, options) {
            return PlaybackMetadataApiFp(configuration).playbackMetadataUnsubscribe(groupId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * PlaybackMetadataApi - object-oriented interface
 * @export
 * @class PlaybackMetadataApi
 * @extends {BaseAPI}
 */
export class PlaybackMetadataApi extends BaseAPI {
    /**
     * Use this command to poll for metadata changes. This command returns a metadataStatus object for the target group.
     * @summary getMetadataStatus
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackMetadataApi
     */
    playbackMetadataGetMetadataStatus(groupId, xSonosCorrId, options) {
        return PlaybackMetadataApiFp(this.configuration).playbackMetadataGetMetadataStatus(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful and sends a metadataStatus event only to the subscriber.
     * @summary subscribe
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackMetadataApi
     */
    playbackMetadataSubscribe(groupId, xSonosCorrId, options) {
        return PlaybackMetadataApiFp(this.configuration).playbackMetadataSubscribe(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the playbackMetadata namespace.   Returns an empty body with a success value of true if successful.
     * @summary unsubscribe
     * @param {string} groupId Target groupId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackMetadataApi
     */
    playbackMetadataUnsubscribe(groupId, xSonosCorrId, options) {
        return PlaybackMetadataApiFp(this.configuration).playbackMetadataUnsubscribe(groupId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * PlaybackSessionApi - fetch parameter creator
 * @export
 */
export const PlaybackSessionApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Use the createSession command in the playbackSession namespace to unconditionally create a new session and clobber any existing sessions.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
         * @summary createSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionCreateSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionCreateSession(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSessionCreateSession.');
            }
            const localVarPath = `/groups/{groupId}/playbackSession`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSessionCreateSessionBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinOrCreateSession command in the playbackSession namespace to join an existing session in the group, if the appId and appContext match the existing session’s. If there is no current session and no other source is playing, joinOrCreateSession creates a new session and joins it.   While this same behavior can be achieved by first calling joinSession and then conditionally calling createSession, using joinOrCreateSession guarantees that another app cannot create a session in between the two commands.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
         * @summary joinOrCreateSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionJoinOrCreateSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionJoinOrCreateSession(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSessionJoinOrCreateSession.');
            }
            const localVarPath = `/groups/{groupId}/playbackSession/joinOrCreate`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSessionJoinOrCreateSessionBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinSession command in the playbackSession namespace to join an existing session in the group. To successfully join the session your app will have to provide the same appId and appContext that was used when creating the session.
         * @summary joinSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionJoinSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionJoinSession(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playbackSessionJoinSession.');
            }
            const localVarPath = `/groups/{groupId}/playbackSession/join`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSessionJoinSessionBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Leaves (disconnects from) the given playback session. The player is responsible for tracking the number of clients connected to the session. When the number of connected clients transitions from 1 to 0 due to an explicit leaveSession command, then the player stops playback and delivers sessionError(ERROR_SESSION_EVICTED) to any clients still subscribed to the session ID. In all other cases, including when the number of connected clients reaches 0 due to an implicit disconnect, then the player maintains playback and keeps the session alive until such time as the music service ends authorization for the stream.   Generally, a client should call leaveSession only in response to an explicit user action to stop controlling to the Sonos group. Changing the queue does not require a new session. Simply call loadCloudQueue with new parameters.    NOTE: Leaving the session does not implicitly unsubscribe a client from receiving playbackSession events. A subscribed client will continue to receive events related to the session until explicitly calling unsubscribe.   This command is only available on the LAN.
         * @summary leaveSession
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLeaveSession(sessionId, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionLeaveSession.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * One way to play audio on Sonos is by using a cloud queue, a list of tracks that you host on a server that the player can access. See Play audio for details. Use the loadCloudQueue command in the playbackSession namespace to load, and optionally start playback of, an item in a cloud queue.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the track, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the track. You should also send playback objects with information about the track in the trackMetadata parameter. This optimization improves the user experience by starting playback for the first track before the player fetches tracks from the cloud queue server.   After receiving the loadCloudQueue command, the player will fetch a window of tracks from the cloud queue server centered around the item with the itemId that your app provided. If the track was deleted, the group will play the next track in the queue. For more details, see the Cloud Queue API /itemWindow endpoint.   All commands in the playback and playbackMetadata namespace also apply to the cloud queue playback. For example, you can send the play or pause command in the playback namespace to play or pause a cloud queue track on a player.
         * @summary loadCloudQueue
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionLoadCloudQueueBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLoadCloudQueue(sessionId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionLoadCloudQueue.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/loadCloudQueue`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSessionLoadCloudQueueBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The loadStreamUrl command in the playbackSession namespace loads a streaming (live) radio station URL and optionally starts playback. Upon successful completion, the player sends a playbackStatus event to your app or hardware integration. Your app should subscribe to the playback namespace before sending the loadStreamUrl command to avoid race conditions in receiving playbackStatus or playbackError events.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the stream, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the stream.
         * @summary loadStreamUrl
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionLoadStreamUrlBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLoadStreamUrl(sessionId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionLoadStreamUrl.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/loadStreamUrl`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSessionLoadStreamUrlBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the refreshCloudQueue command in the playbackSession namespace to signal the player to re-fetch tracks from the cloud queue server centered around the current item. See the Cloud Queue API /itemWindow endpoint for details.   This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Your app should only use this command when it detects a change in the cloud queue content that should immediately be reflected in the group. For example, if a user removes the currently playing track with your app, use this command to remove the track from the group and stop it from playing. This helps provide a good user experience.
         * @summary refreshCloudQueue
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionRefreshCloudQueue(sessionId, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionRefreshCloudQueue.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/refreshCloudQueue`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Rejoins an existing session. This command joins the current session if the session ID matches the current session ID.   NOTE: This command can only be called by Lechmere to support managing sessions for cloud-based clients when groups move on the LAN.
         * @summary rejoinSession
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionRejoinSession(sessionId, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionRejoinSession.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/rejoin`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the skipToItem command in the playbackSession namespace to skip to the track with the specified itemId in a cloud queue and optionally seek and initiate playback. This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Use playOnCompletion to start playing the cloud queue item being loaded, so your app doesn’t have to send an extra play command.   Most of the times your app already has access to the track metadata of the cloud queue item that is being loaded. Use the optional trackMetadata parameter to provide that information so the group can start playing the track immediately after receiving the loadCloudQueue command. This optimization improves the user experience by starting playback before the player fetches tracks from the cloud queue server.
         * @summary skipToItem
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionSkipToItemBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSkipToItem(sessionId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionSkipToItem.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/skipToItem`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSessionSkipToItemBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the playbackSession namespace for a specific sessionId. Your app can only be subscribed to one session at a time.   Returns an empty body with a success value of true if successful.
         * @summary subscribe
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSubscribe(sessionId, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionSubscribe.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/subscription`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the suspend command in the playbackSession namespace to suspend a specified session. The player will clear the stored item window of tracks and send a playbackStatus event to tell clients that the current item is null.   As described in a cloud queue use case in Play audio, if your app sends a play command when the cloud queue is empty, the player will attempt to resume playback of content that was playing prior to this command. For example, if the user was previously listening to a radio station or had a playlist queued, it will attempt to resume playback of that content.   When a session is suspended, the player won’t revert to the previous music source in this case. Instead, the player delivers a sessionInfo event with a suspended value of true for the suspended session. The session stays suspended until one of the following occurs:   The player receives a play command. If the player receives a play command, it sends a sessionInfo event with a suspended value of false.  The player receives either a loadCloudQueue or skipToItem command for the suspended session. The player follows these commands as specified.  The player receives a createSession command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.  The user or an action picks a new music source. This can either be from the Sonos app or through the loadFavorite command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.
         * @summary suspend
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionSuspendBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSuspend(sessionId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionSuspend.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/suspend`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaybackSessionSuspendBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the playbackSession namespace.   Returns an empty body with a success value of true if successful.
         * @summary unsubscribe
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionUnsubscribe(sessionId, xSonosCorrId, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling playbackSessionUnsubscribe.');
            }
            const localVarPath = `/sessions/{sessionId}/playbackSession/subscription`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PlaybackSessionApi - functional programming interface
 * @export
 */
export const PlaybackSessionApiFp = function (configuration) {
    return {
        /**
         * Use the createSession command in the playbackSession namespace to unconditionally create a new session and clobber any existing sessions.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
         * @summary createSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionCreateSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionCreateSession(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionCreateSession(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinOrCreateSession command in the playbackSession namespace to join an existing session in the group, if the appId and appContext match the existing session’s. If there is no current session and no other source is playing, joinOrCreateSession creates a new session and joins it.   While this same behavior can be achieved by first calling joinSession and then conditionally calling createSession, using joinOrCreateSession guarantees that another app cannot create a session in between the two commands.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
         * @summary joinOrCreateSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionJoinOrCreateSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionJoinOrCreateSession(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionJoinOrCreateSession(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinSession command in the playbackSession namespace to join an existing session in the group. To successfully join the session your app will have to provide the same appId and appContext that was used when creating the session.
         * @summary joinSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionJoinSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionJoinSession(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionJoinSession(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Leaves (disconnects from) the given playback session. The player is responsible for tracking the number of clients connected to the session. When the number of connected clients transitions from 1 to 0 due to an explicit leaveSession command, then the player stops playback and delivers sessionError(ERROR_SESSION_EVICTED) to any clients still subscribed to the session ID. In all other cases, including when the number of connected clients reaches 0 due to an implicit disconnect, then the player maintains playback and keeps the session alive until such time as the music service ends authorization for the stream.   Generally, a client should call leaveSession only in response to an explicit user action to stop controlling to the Sonos group. Changing the queue does not require a new session. Simply call loadCloudQueue with new parameters.    NOTE: Leaving the session does not implicitly unsubscribe a client from receiving playbackSession events. A subscribed client will continue to receive events related to the session until explicitly calling unsubscribe.   This command is only available on the LAN.
         * @summary leaveSession
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLeaveSession(sessionId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionLeaveSession(sessionId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * One way to play audio on Sonos is by using a cloud queue, a list of tracks that you host on a server that the player can access. See Play audio for details. Use the loadCloudQueue command in the playbackSession namespace to load, and optionally start playback of, an item in a cloud queue.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the track, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the track. You should also send playback objects with information about the track in the trackMetadata parameter. This optimization improves the user experience by starting playback for the first track before the player fetches tracks from the cloud queue server.   After receiving the loadCloudQueue command, the player will fetch a window of tracks from the cloud queue server centered around the item with the itemId that your app provided. If the track was deleted, the group will play the next track in the queue. For more details, see the Cloud Queue API /itemWindow endpoint.   All commands in the playback and playbackMetadata namespace also apply to the cloud queue playback. For example, you can send the play or pause command in the playback namespace to play or pause a cloud queue track on a player.
         * @summary loadCloudQueue
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionLoadCloudQueueBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLoadCloudQueue(sessionId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionLoadCloudQueue(sessionId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * The loadStreamUrl command in the playbackSession namespace loads a streaming (live) radio station URL and optionally starts playback. Upon successful completion, the player sends a playbackStatus event to your app or hardware integration. Your app should subscribe to the playback namespace before sending the loadStreamUrl command to avoid race conditions in receiving playbackStatus or playbackError events.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the stream, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the stream.
         * @summary loadStreamUrl
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionLoadStreamUrlBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLoadStreamUrl(sessionId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionLoadStreamUrl(sessionId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the refreshCloudQueue command in the playbackSession namespace to signal the player to re-fetch tracks from the cloud queue server centered around the current item. See the Cloud Queue API /itemWindow endpoint for details.   This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Your app should only use this command when it detects a change in the cloud queue content that should immediately be reflected in the group. For example, if a user removes the currently playing track with your app, use this command to remove the track from the group and stop it from playing. This helps provide a good user experience.
         * @summary refreshCloudQueue
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionRefreshCloudQueue(sessionId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionRefreshCloudQueue(sessionId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Rejoins an existing session. This command joins the current session if the session ID matches the current session ID.   NOTE: This command can only be called by Lechmere to support managing sessions for cloud-based clients when groups move on the LAN.
         * @summary rejoinSession
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionRejoinSession(sessionId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionRejoinSession(sessionId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the skipToItem command in the playbackSession namespace to skip to the track with the specified itemId in a cloud queue and optionally seek and initiate playback. This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Use playOnCompletion to start playing the cloud queue item being loaded, so your app doesn’t have to send an extra play command.   Most of the times your app already has access to the track metadata of the cloud queue item that is being loaded. Use the optional trackMetadata parameter to provide that information so the group can start playing the track immediately after receiving the loadCloudQueue command. This optimization improves the user experience by starting playback before the player fetches tracks from the cloud queue server.
         * @summary skipToItem
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionSkipToItemBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSkipToItem(sessionId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionSkipToItem(sessionId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the playbackSession namespace for a specific sessionId. Your app can only be subscribed to one session at a time.   Returns an empty body with a success value of true if successful.
         * @summary subscribe
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSubscribe(sessionId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionSubscribe(sessionId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the suspend command in the playbackSession namespace to suspend a specified session. The player will clear the stored item window of tracks and send a playbackStatus event to tell clients that the current item is null.   As described in a cloud queue use case in Play audio, if your app sends a play command when the cloud queue is empty, the player will attempt to resume playback of content that was playing prior to this command. For example, if the user was previously listening to a radio station or had a playlist queued, it will attempt to resume playback of that content.   When a session is suspended, the player won’t revert to the previous music source in this case. Instead, the player delivers a sessionInfo event with a suspended value of true for the suspended session. The session stays suspended until one of the following occurs:   The player receives a play command. If the player receives a play command, it sends a sessionInfo event with a suspended value of false.  The player receives either a loadCloudQueue or skipToItem command for the suspended session. The player follows these commands as specified.  The player receives a createSession command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.  The user or an action picks a new music source. This can either be from the Sonos app or through the loadFavorite command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.
         * @summary suspend
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionSuspendBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSuspend(sessionId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionSuspend(sessionId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the playbackSession namespace.   Returns an empty body with a success value of true if successful.
         * @summary unsubscribe
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionUnsubscribe(sessionId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaybackSessionApiFetchParamCreator(configuration).playbackSessionUnsubscribe(sessionId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * PlaybackSessionApi - factory interface
 * @export
 */
export const PlaybackSessionApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Use the createSession command in the playbackSession namespace to unconditionally create a new session and clobber any existing sessions.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
         * @summary createSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionCreateSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionCreateSession(groupId, body, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionCreateSession(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinOrCreateSession command in the playbackSession namespace to join an existing session in the group, if the appId and appContext match the existing session’s. If there is no current session and no other source is playing, joinOrCreateSession creates a new session and joins it.   While this same behavior can be achieved by first calling joinSession and then conditionally calling createSession, using joinOrCreateSession guarantees that another app cannot create a session in between the two commands.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
         * @summary joinOrCreateSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionJoinOrCreateSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionJoinOrCreateSession(groupId, body, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionJoinOrCreateSession(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinSession command in the playbackSession namespace to join an existing session in the group. To successfully join the session your app will have to provide the same appId and appContext that was used when creating the session.
         * @summary joinSession
         * @param {string} groupId Target groupId
         * @param {PlaybackSessionJoinSessionBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionJoinSession(groupId, body, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionJoinSession(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Leaves (disconnects from) the given playback session. The player is responsible for tracking the number of clients connected to the session. When the number of connected clients transitions from 1 to 0 due to an explicit leaveSession command, then the player stops playback and delivers sessionError(ERROR_SESSION_EVICTED) to any clients still subscribed to the session ID. In all other cases, including when the number of connected clients reaches 0 due to an implicit disconnect, then the player maintains playback and keeps the session alive until such time as the music service ends authorization for the stream.   Generally, a client should call leaveSession only in response to an explicit user action to stop controlling to the Sonos group. Changing the queue does not require a new session. Simply call loadCloudQueue with new parameters.    NOTE: Leaving the session does not implicitly unsubscribe a client from receiving playbackSession events. A subscribed client will continue to receive events related to the session until explicitly calling unsubscribe.   This command is only available on the LAN.
         * @summary leaveSession
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLeaveSession(sessionId, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionLeaveSession(sessionId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * One way to play audio on Sonos is by using a cloud queue, a list of tracks that you host on a server that the player can access. See Play audio for details. Use the loadCloudQueue command in the playbackSession namespace to load, and optionally start playback of, an item in a cloud queue.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the track, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the track. You should also send playback objects with information about the track in the trackMetadata parameter. This optimization improves the user experience by starting playback for the first track before the player fetches tracks from the cloud queue server.   After receiving the loadCloudQueue command, the player will fetch a window of tracks from the cloud queue server centered around the item with the itemId that your app provided. If the track was deleted, the group will play the next track in the queue. For more details, see the Cloud Queue API /itemWindow endpoint.   All commands in the playback and playbackMetadata namespace also apply to the cloud queue playback. For example, you can send the play or pause command in the playback namespace to play or pause a cloud queue track on a player.
         * @summary loadCloudQueue
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionLoadCloudQueueBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLoadCloudQueue(sessionId, body, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionLoadCloudQueue(sessionId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * The loadStreamUrl command in the playbackSession namespace loads a streaming (live) radio station URL and optionally starts playback. Upon successful completion, the player sends a playbackStatus event to your app or hardware integration. Your app should subscribe to the playback namespace before sending the loadStreamUrl command to avoid race conditions in receiving playbackStatus or playbackError events.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the stream, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the stream.
         * @summary loadStreamUrl
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionLoadStreamUrlBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionLoadStreamUrl(sessionId, body, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionLoadStreamUrl(sessionId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the refreshCloudQueue command in the playbackSession namespace to signal the player to re-fetch tracks from the cloud queue server centered around the current item. See the Cloud Queue API /itemWindow endpoint for details.   This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Your app should only use this command when it detects a change in the cloud queue content that should immediately be reflected in the group. For example, if a user removes the currently playing track with your app, use this command to remove the track from the group and stop it from playing. This helps provide a good user experience.
         * @summary refreshCloudQueue
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionRefreshCloudQueue(sessionId, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionRefreshCloudQueue(sessionId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Rejoins an existing session. This command joins the current session if the session ID matches the current session ID.   NOTE: This command can only be called by Lechmere to support managing sessions for cloud-based clients when groups move on the LAN.
         * @summary rejoinSession
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionRejoinSession(sessionId, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionRejoinSession(sessionId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the skipToItem command in the playbackSession namespace to skip to the track with the specified itemId in a cloud queue and optionally seek and initiate playback. This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Use playOnCompletion to start playing the cloud queue item being loaded, so your app doesn’t have to send an extra play command.   Most of the times your app already has access to the track metadata of the cloud queue item that is being loaded. Use the optional trackMetadata parameter to provide that information so the group can start playing the track immediately after receiving the loadCloudQueue command. This optimization improves the user experience by starting playback before the player fetches tracks from the cloud queue server.
         * @summary skipToItem
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionSkipToItemBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSkipToItem(sessionId, body, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionSkipToItem(sessionId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the playbackSession namespace for a specific sessionId. Your app can only be subscribed to one session at a time.   Returns an empty body with a success value of true if successful.
         * @summary subscribe
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSubscribe(sessionId, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionSubscribe(sessionId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the suspend command in the playbackSession namespace to suspend a specified session. The player will clear the stored item window of tracks and send a playbackStatus event to tell clients that the current item is null.   As described in a cloud queue use case in Play audio, if your app sends a play command when the cloud queue is empty, the player will attempt to resume playback of content that was playing prior to this command. For example, if the user was previously listening to a radio station or had a playlist queued, it will attempt to resume playback of that content.   When a session is suspended, the player won’t revert to the previous music source in this case. Instead, the player delivers a sessionInfo event with a suspended value of true for the suspended session. The session stays suspended until one of the following occurs:   The player receives a play command. If the player receives a play command, it sends a sessionInfo event with a suspended value of false.  The player receives either a loadCloudQueue or skipToItem command for the suspended session. The player follows these commands as specified.  The player receives a createSession command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.  The user or an action picks a new music source. This can either be from the Sonos app or through the loadFavorite command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.
         * @summary suspend
         * @param {string} sessionId Target sessionId
         * @param {PlaybackSessionSuspendBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionSuspend(sessionId, body, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionSuspend(sessionId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the playbackSession namespace.   Returns an empty body with a success value of true if successful.
         * @summary unsubscribe
         * @param {string} sessionId Target sessionId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playbackSessionUnsubscribe(sessionId, xSonosCorrId, options) {
            return PlaybackSessionApiFp(configuration).playbackSessionUnsubscribe(sessionId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * PlaybackSessionApi - object-oriented interface
 * @export
 * @class PlaybackSessionApi
 * @extends {BaseAPI}
 */
export class PlaybackSessionApi extends BaseAPI {
    /**
     * Use the createSession command in the playbackSession namespace to unconditionally create a new session and clobber any existing sessions.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
     * @summary createSession
     * @param {string} groupId Target groupId
     * @param {PlaybackSessionCreateSessionBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionCreateSession(groupId, body, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionCreateSession(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinOrCreateSession command in the playbackSession namespace to join an existing session in the group, if the appId and appContext match the existing session’s. If there is no current session and no other source is playing, joinOrCreateSession creates a new session and joins it.   While this same behavior can be achieved by first calling joinSession and then conditionally calling createSession, using joinOrCreateSession guarantees that another app cannot create a session in between the two commands.   Use appContext to determine how multiple instances of your app can share control of a session. For example, if you provide a user account identifier as appContext, then two instances of your app logged into the same user account would be able to control the same session on a group. If you choose to implement your app to always provide an appContext that is unique for all app instances, then only one app instance can control a session at any time.   Use customData to save information in the session that your app finds useful. For example, your app could store a playlist identifier in customData, so that another app instance could automatically load the right playlist when joining a session.
     * @summary joinOrCreateSession
     * @param {string} groupId Target groupId
     * @param {PlaybackSessionJoinOrCreateSessionBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionJoinOrCreateSession(groupId, body, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionJoinOrCreateSession(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Use the joinSession command in the playbackSession namespace to join an existing session in the group. To successfully join the session your app will have to provide the same appId and appContext that was used when creating the session.
     * @summary joinSession
     * @param {string} groupId Target groupId
     * @param {PlaybackSessionJoinSessionBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionJoinSession(groupId, body, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionJoinSession(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Leaves (disconnects from) the given playback session. The player is responsible for tracking the number of clients connected to the session. When the number of connected clients transitions from 1 to 0 due to an explicit leaveSession command, then the player stops playback and delivers sessionError(ERROR_SESSION_EVICTED) to any clients still subscribed to the session ID. In all other cases, including when the number of connected clients reaches 0 due to an implicit disconnect, then the player maintains playback and keeps the session alive until such time as the music service ends authorization for the stream.   Generally, a client should call leaveSession only in response to an explicit user action to stop controlling to the Sonos group. Changing the queue does not require a new session. Simply call loadCloudQueue with new parameters.    NOTE: Leaving the session does not implicitly unsubscribe a client from receiving playbackSession events. A subscribed client will continue to receive events related to the session until explicitly calling unsubscribe.   This command is only available on the LAN.
     * @summary leaveSession
     * @param {string} sessionId Target sessionId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionLeaveSession(sessionId, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionLeaveSession(sessionId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * One way to play audio on Sonos is by using a cloud queue, a list of tracks that you host on a server that the player can access. See Play audio for details. Use the loadCloudQueue command in the playbackSession namespace to load, and optionally start playback of, an item in a cloud queue.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the track, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the track. You should also send playback objects with information about the track in the trackMetadata parameter. This optimization improves the user experience by starting playback for the first track before the player fetches tracks from the cloud queue server.   After receiving the loadCloudQueue command, the player will fetch a window of tracks from the cloud queue server centered around the item with the itemId that your app provided. If the track was deleted, the group will play the next track in the queue. For more details, see the Cloud Queue API /itemWindow endpoint.   All commands in the playback and playbackMetadata namespace also apply to the cloud queue playback. For example, you can send the play or pause command in the playback namespace to play or pause a cloud queue track on a player.
     * @summary loadCloudQueue
     * @param {string} sessionId Target sessionId
     * @param {PlaybackSessionLoadCloudQueueBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionLoadCloudQueue(sessionId, body, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionLoadCloudQueue(sessionId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * The loadStreamUrl command in the playbackSession namespace loads a streaming (live) radio station URL and optionally starts playback. Upon successful completion, the player sends a playbackStatus event to your app or hardware integration. Your app should subscribe to the playback namespace before sending the loadStreamUrl command to avoid race conditions in receiving playbackStatus or playbackError events.   This command requires that your app has an open playback session with a cloud queue, created or joined using the createSession, joinSession, or joinOrCreateSession command.   If you want to immediately start playing the stream, set the playOnCompletion parameter to true. This bypasses the need to send a play command after the player loads the stream.
     * @summary loadStreamUrl
     * @param {string} sessionId Target sessionId
     * @param {PlaybackSessionLoadStreamUrlBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionLoadStreamUrl(sessionId, body, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionLoadStreamUrl(sessionId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the refreshCloudQueue command in the playbackSession namespace to signal the player to re-fetch tracks from the cloud queue server centered around the current item. See the Cloud Queue API /itemWindow endpoint for details.   This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Your app should only use this command when it detects a change in the cloud queue content that should immediately be reflected in the group. For example, if a user removes the currently playing track with your app, use this command to remove the track from the group and stop it from playing. This helps provide a good user experience.
     * @summary refreshCloudQueue
     * @param {string} sessionId Target sessionId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionRefreshCloudQueue(sessionId, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionRefreshCloudQueue(sessionId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Warning: This command, along with all other playbackSession commands used to join or leave sessions over a persistent connections with the player, has been deprecated. The client must still create a session with the createSession command, and the generated sessionId should be cached and used with subsequent commands.   Rejoins an existing session. This command joins the current session if the session ID matches the current session ID.   NOTE: This command can only be called by Lechmere to support managing sessions for cloud-based clients when groups move on the LAN.
     * @summary rejoinSession
     * @param {string} sessionId Target sessionId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionRejoinSession(sessionId, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionRejoinSession(sessionId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the skipToItem command in the playbackSession namespace to skip to the track with the specified itemId in a cloud queue and optionally seek and initiate playback. This command requires your app to have either created a new session or joined an existing session, and also loaded a cloud queue on the group with loadCloudQueue.   Use playOnCompletion to start playing the cloud queue item being loaded, so your app doesn’t have to send an extra play command.   Most of the times your app already has access to the track metadata of the cloud queue item that is being loaded. Use the optional trackMetadata parameter to provide that information so the group can start playing the track immediately after receiving the loadCloudQueue command. This optimization improves the user experience by starting playback before the player fetches tracks from the cloud queue server.
     * @summary skipToItem
     * @param {string} sessionId Target sessionId
     * @param {PlaybackSessionSkipToItemBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionSkipToItem(sessionId, body, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionSkipToItem(sessionId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the playbackSession namespace for a specific sessionId. Your app can only be subscribed to one session at a time.   Returns an empty body with a success value of true if successful.
     * @summary subscribe
     * @param {string} sessionId Target sessionId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionSubscribe(sessionId, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionSubscribe(sessionId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the suspend command in the playbackSession namespace to suspend a specified session. The player will clear the stored item window of tracks and send a playbackStatus event to tell clients that the current item is null.   As described in a cloud queue use case in Play audio, if your app sends a play command when the cloud queue is empty, the player will attempt to resume playback of content that was playing prior to this command. For example, if the user was previously listening to a radio station or had a playlist queued, it will attempt to resume playback of that content.   When a session is suspended, the player won’t revert to the previous music source in this case. Instead, the player delivers a sessionInfo event with a suspended value of true for the suspended session. The session stays suspended until one of the following occurs:   The player receives a play command. If the player receives a play command, it sends a sessionInfo event with a suspended value of false.  The player receives either a loadCloudQueue or skipToItem command for the suspended session. The player follows these commands as specified.  The player receives a createSession command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.  The user or an action picks a new music source. This can either be from the Sonos app or through the loadFavorite command. The suspended session receives an ERROR_SESSION_EVICTED sessionError.
     * @summary suspend
     * @param {string} sessionId Target sessionId
     * @param {PlaybackSessionSuspendBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionSuspend(sessionId, body, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionSuspend(sessionId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the playbackSession namespace.   Returns an empty body with a success value of true if successful.
     * @summary unsubscribe
     * @param {string} sessionId Target sessionId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaybackSessionApi
     */
    playbackSessionUnsubscribe(sessionId, xSonosCorrId, options) {
        return PlaybackSessionApiFp(this.configuration).playbackSessionUnsubscribe(sessionId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * PlayerVolumeApi - fetch parameter creator
 * @export
 */
export const PlayerVolumeApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Ducking temporarily lowers the zone's volume without affecting the observed system volume. Sending another \"duck\" command to a system that is already ducking does not affect the output level. However, it does reset the duration.
         * @summary duck
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeDuckBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeDuck(playerId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeDuck.');
            }
            const localVarPath = `/players/{playerId}/playerVolume/duck`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlayerVolumeDuckBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the getVolume command in the playerVolume namespace to get the volume and mute state of a player.
         * @summary getVolume
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeGetVolume(playerId, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeGetVolume.');
            }
            const localVarPath = `/players/{playerId}/playerVolume`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setMute command in the playerVolume namespace to mute and unmute a player.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the mute state was changed.
         * @summary setMute
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetMuteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetMute(playerId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeSetMute.');
            }
            const localVarPath = `/players/{playerId}/playerVolume/mute`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlayerVolumeSetMuteBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setRelativeVolume command in the playerVolume namespace to increase or decrease volume for a player and unmute the player if muted.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.
         * @summary setRelativeVolume
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetRelativeVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetRelativeVolume(playerId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeSetRelativeVolume.');
            }
            const localVarPath = `/players/{playerId}/playerVolume/relative`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlayerVolumeSetRelativeVolumeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the setVolume command in the playerVolume namespace to set player volume and mute state.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.  Additionally, if setVolume is called on a player that has a fixed player volume state that is true, the command will fail and you will receive an ERROR_COMMAND_FAILED globalError. This is because players with fixed volumes cannot be changed, for example, a CONNECT with fixed line out would respond with this error if your app tried to set the volume on it.
         * @summary setVolume
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetVolume(playerId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeSetVolume.');
            }
            const localVarPath = `/players/{playerId}/playerVolume`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlayerVolumeSetVolumeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the playerVolume namespace.   Returns an empty body with a success value of true if successful and sends a playerVolume event only for the subscriber.
         * @summary subscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSubscribe(playerId, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeSubscribe.');
            }
            const localVarPath = `/players/{playerId}/playerVolume/subscription`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This command is the dual of duck. The volume is restored if there is a ducking operation in progress. Otherwise, the system accepts this command and does not change any internal state.
         * @summary unduck
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeUnduck(playerId, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeUnduck.');
            }
            const localVarPath = `/players/{playerId}/playerVolume/unduck`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the playerVolume namespace.
         * @summary unsubscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeUnsubscribe(playerId, xSonosCorrId, options = {}) {
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId', 'Required parameter playerId was null or undefined when calling playerVolumeUnsubscribe.');
            }
            const localVarPath = `/players/{playerId}/playerVolume/subscription`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PlayerVolumeApi - functional programming interface
 * @export
 */
export const PlayerVolumeApiFp = function (configuration) {
    return {
        /**
         * Ducking temporarily lowers the zone's volume without affecting the observed system volume. Sending another \"duck\" command to a system that is already ducking does not affect the output level. However, it does reset the duration.
         * @summary duck
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeDuckBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeDuck(playerId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeDuck(playerId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the getVolume command in the playerVolume namespace to get the volume and mute state of a player.
         * @summary getVolume
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeGetVolume(playerId, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeGetVolume(playerId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setMute command in the playerVolume namespace to mute and unmute a player.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the mute state was changed.
         * @summary setMute
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetMuteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetMute(playerId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeSetMute(playerId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setRelativeVolume command in the playerVolume namespace to increase or decrease volume for a player and unmute the player if muted.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.
         * @summary setRelativeVolume
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetRelativeVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetRelativeVolume(playerId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeSetRelativeVolume(playerId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the setVolume command in the playerVolume namespace to set player volume and mute state.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.  Additionally, if setVolume is called on a player that has a fixed player volume state that is true, the command will fail and you will receive an ERROR_COMMAND_FAILED globalError. This is because players with fixed volumes cannot be changed, for example, a CONNECT with fixed line out would respond with this error if your app tried to set the volume on it.
         * @summary setVolume
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetVolume(playerId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeSetVolume(playerId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the playerVolume namespace.   Returns an empty body with a success value of true if successful and sends a playerVolume event only for the subscriber.
         * @summary subscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSubscribe(playerId, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeSubscribe(playerId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * This command is the dual of duck. The volume is restored if there is a ducking operation in progress. Otherwise, the system accepts this command and does not change any internal state.
         * @summary unduck
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeUnduck(playerId, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeUnduck(playerId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the playerVolume namespace.
         * @summary unsubscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeUnsubscribe(playerId, xSonosCorrId, options) {
            const localVarFetchArgs = PlayerVolumeApiFetchParamCreator(configuration).playerVolumeUnsubscribe(playerId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * PlayerVolumeApi - factory interface
 * @export
 */
export const PlayerVolumeApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Ducking temporarily lowers the zone's volume without affecting the observed system volume. Sending another \"duck\" command to a system that is already ducking does not affect the output level. However, it does reset the duration.
         * @summary duck
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeDuckBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeDuck(playerId, body, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeDuck(playerId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the getVolume command in the playerVolume namespace to get the volume and mute state of a player.
         * @summary getVolume
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeGetVolume(playerId, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeGetVolume(playerId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setMute command in the playerVolume namespace to mute and unmute a player.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the mute state was changed.
         * @summary setMute
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetMuteBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetMute(playerId, body, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeSetMute(playerId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setRelativeVolume command in the playerVolume namespace to increase or decrease volume for a player and unmute the player if muted.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.
         * @summary setRelativeVolume
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetRelativeVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetRelativeVolume(playerId, body, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeSetRelativeVolume(playerId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the setVolume command in the playerVolume namespace to set player volume and mute state.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.  Additionally, if setVolume is called on a player that has a fixed player volume state that is true, the command will fail and you will receive an ERROR_COMMAND_FAILED globalError. This is because players with fixed volumes cannot be changed, for example, a CONNECT with fixed line out would respond with this error if your app tried to set the volume on it.
         * @summary setVolume
         * @param {string} playerId Target playerId
         * @param {PlayerVolumeSetVolumeBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSetVolume(playerId, body, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeSetVolume(playerId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the playerVolume namespace.   Returns an empty body with a success value of true if successful and sends a playerVolume event only for the subscriber.
         * @summary subscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeSubscribe(playerId, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeSubscribe(playerId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * This command is the dual of duck. The volume is restored if there is a ducking operation in progress. Otherwise, the system accepts this command and does not change any internal state.
         * @summary unduck
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeUnduck(playerId, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeUnduck(playerId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the playerVolume namespace.
         * @summary unsubscribe
         * @param {string} playerId Target playerId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playerVolumeUnsubscribe(playerId, xSonosCorrId, options) {
            return PlayerVolumeApiFp(configuration).playerVolumeUnsubscribe(playerId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * PlayerVolumeApi - object-oriented interface
 * @export
 * @class PlayerVolumeApi
 * @extends {BaseAPI}
 */
export class PlayerVolumeApi extends BaseAPI {
    /**
     * Ducking temporarily lowers the zone's volume without affecting the observed system volume. Sending another \"duck\" command to a system that is already ducking does not affect the output level. However, it does reset the duration.
     * @summary duck
     * @param {string} playerId Target playerId
     * @param {PlayerVolumeDuckBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeDuck(playerId, body, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeDuck(playerId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the getVolume command in the playerVolume namespace to get the volume and mute state of a player.
     * @summary getVolume
     * @param {string} playerId Target playerId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeGetVolume(playerId, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeGetVolume(playerId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setMute command in the playerVolume namespace to mute and unmute a player.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the mute state was changed.
     * @summary setMute
     * @param {string} playerId Target playerId
     * @param {PlayerVolumeSetMuteBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeSetMute(playerId, body, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeSetMute(playerId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setRelativeVolume command in the playerVolume namespace to increase or decrease volume for a player and unmute the player if muted.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.
     * @summary setRelativeVolume
     * @param {string} playerId Target playerId
     * @param {PlayerVolumeSetRelativeVolumeBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeSetRelativeVolume(playerId, body, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeSetRelativeVolume(playerId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the setVolume command in the playerVolume namespace to set player volume and mute state.   Returns an empty body with a success value of true if successful. The player generates a playerVolume event if the volume was changed.  Additionally, if setVolume is called on a player that has a fixed player volume state that is true, the command will fail and you will receive an ERROR_COMMAND_FAILED globalError. This is because players with fixed volumes cannot be changed, for example, a CONNECT with fixed line out would respond with this error if your app tried to set the volume on it.
     * @summary setVolume
     * @param {string} playerId Target playerId
     * @param {PlayerVolumeSetVolumeBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeSetVolume(playerId, body, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeSetVolume(playerId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the playerVolume namespace.   Returns an empty body with a success value of true if successful and sends a playerVolume event only for the subscriber.
     * @summary subscribe
     * @param {string} playerId Target playerId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeSubscribe(playerId, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeSubscribe(playerId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * This command is the dual of duck. The volume is restored if there is a ducking operation in progress. Otherwise, the system accepts this command and does not change any internal state.
     * @summary unduck
     * @param {string} playerId Target playerId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeUnduck(playerId, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeUnduck(playerId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the playerVolume namespace.
     * @summary unsubscribe
     * @param {string} playerId Target playerId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerVolumeApi
     */
    playerVolumeUnsubscribe(playerId, xSonosCorrId, options) {
        return PlayerVolumeApiFp(this.configuration).playerVolumeUnsubscribe(playerId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
/**
 * PlaylistsApi - fetch parameter creator
 * @export
 */
export const PlaylistsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * The getPlaylist command retrieves the track information associated with a particular playlist.   This command returns a PlaylistSummary object, which contains an array of PlaylistTrack objects.
         * @summary getPlaylist
         * @param {string} householdId Target householdId
         * @param {string} playlistId Target playlistId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsGetPlaylist(householdId, playlistId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling playlistsGetPlaylist.');
            }
            // verify required parameter 'playlistId' is not null or undefined
            if (playlistId === null || playlistId === undefined) {
                throw new RequiredError('playlistId', 'Required parameter playlistId was null or undefined when calling playlistsGetPlaylist.');
            }
            const localVarPath = `/households/{householdId}/playlists/{playlistId}`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)))
                .replace(`{${"playlistId"}}`, encodeURIComponent(String(playlistId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the getPlaylists command to get the list of Sonos playlists for a household. Playlists do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos favorites. See the Save your favorites section in the Sonos user guide for details about My Sonos.   Returns a playlistsList object, which is an array of playlist objects with a version number.
         * @summary getPlaylists
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsGetPlaylists(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling playlistsGetPlaylists.');
            }
            const localVarPath = `/households/{householdId}/playlists`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The loadPlaylist command activates a playlist within the default playback session. This command interrupts any active private playback sessions. Sonos adds tracks from the playlist to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
         * @summary loadPlaylist
         * @param {string} groupId Target groupId
         * @param {PlaylistsLoadPlaylistBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsLoadPlaylist(groupId, body, xSonosCorrId, options = {}) {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId', 'Required parameter groupId was null or undefined when calling playlistsLoadPlaylist.');
            }
            const localVarPath = `/groups/{groupId}/playlists`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PlaylistsLoadPlaylistBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to subscribe to events in the playlists namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos playlists. Your app can then choose to fetch the playlists as needed whenever the version changes. This is because the playlistsList object can be large for asynchronous events.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsSubscribe(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling playlistsSubscribe.');
            }
            const localVarPath = `/households/{householdId}/playlists/subscription`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this command to unsubscribe to events in the playlists namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsUnsubscribe(householdId, xSonosCorrId, options = {}) {
            // verify required parameter 'householdId' is not null or undefined
            if (householdId === null || householdId === undefined) {
                throw new RequiredError('householdId', 'Required parameter householdId was null or undefined when calling playlistsUnsubscribe.');
            }
            const localVarPath = `/households/{householdId}/playlists/subscription`
                .replace(`{${"householdId"}}`, encodeURIComponent(String(householdId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication BearerAuth required
            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("OAuth2", ["playback-control-all", "hh-config", "hh-config-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (xSonosCorrId !== undefined && xSonosCorrId !== null) {
                localVarHeaderParameter['X-Sonos-Corr-Id'] = String(xSonosCorrId);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PlaylistsApi - functional programming interface
 * @export
 */
export const PlaylistsApiFp = function (configuration) {
    return {
        /**
         * The getPlaylist command retrieves the track information associated with a particular playlist.   This command returns a PlaylistSummary object, which contains an array of PlaylistTrack objects.
         * @summary getPlaylist
         * @param {string} householdId Target householdId
         * @param {string} playlistId Target playlistId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsGetPlaylist(householdId, playlistId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaylistsApiFetchParamCreator(configuration).playlistsGetPlaylist(householdId, playlistId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use the getPlaylists command to get the list of Sonos playlists for a household. Playlists do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos favorites. See the Save your favorites section in the Sonos user guide for details about My Sonos.   Returns a playlistsList object, which is an array of playlist objects with a version number.
         * @summary getPlaylists
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsGetPlaylists(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaylistsApiFetchParamCreator(configuration).playlistsGetPlaylists(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * The loadPlaylist command activates a playlist within the default playback session. This command interrupts any active private playback sessions. Sonos adds tracks from the playlist to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
         * @summary loadPlaylist
         * @param {string} groupId Target groupId
         * @param {PlaylistsLoadPlaylistBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsLoadPlaylist(groupId, body, xSonosCorrId, options) {
            const localVarFetchArgs = PlaylistsApiFetchParamCreator(configuration).playlistsLoadPlaylist(groupId, body, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to subscribe to events in the playlists namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos playlists. Your app can then choose to fetch the playlists as needed whenever the version changes. This is because the playlistsList object can be large for asynchronous events.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsSubscribe(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaylistsApiFetchParamCreator(configuration).playlistsSubscribe(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
        /**
         * Use this command to unsubscribe to events in the playlists namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsUnsubscribe(householdId, xSonosCorrId, options) {
            const localVarFetchArgs = PlaylistsApiFetchParamCreator(configuration).playlistsUnsubscribe(householdId, xSonosCorrId, options);
            return async (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                else {
                    throw response;
                }
            };
        },
    };
};
/**
 * PlaylistsApi - factory interface
 * @export
 */
export const PlaylistsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * The getPlaylist command retrieves the track information associated with a particular playlist.   This command returns a PlaylistSummary object, which contains an array of PlaylistTrack objects.
         * @summary getPlaylist
         * @param {string} householdId Target householdId
         * @param {string} playlistId Target playlistId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsGetPlaylist(householdId, playlistId, xSonosCorrId, options) {
            return PlaylistsApiFp(configuration).playlistsGetPlaylist(householdId, playlistId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use the getPlaylists command to get the list of Sonos playlists for a household. Playlists do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos favorites. See the Save your favorites section in the Sonos user guide for details about My Sonos.   Returns a playlistsList object, which is an array of playlist objects with a version number.
         * @summary getPlaylists
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsGetPlaylists(householdId, xSonosCorrId, options) {
            return PlaylistsApiFp(configuration).playlistsGetPlaylists(householdId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * The loadPlaylist command activates a playlist within the default playback session. This command interrupts any active private playback sessions. Sonos adds tracks from the playlist to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
         * @summary loadPlaylist
         * @param {string} groupId Target groupId
         * @param {PlaylistsLoadPlaylistBody} [body]
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsLoadPlaylist(groupId, body, xSonosCorrId, options) {
            return PlaylistsApiFp(configuration).playlistsLoadPlaylist(groupId, body, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to subscribe to events in the playlists namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos playlists. Your app can then choose to fetch the playlists as needed whenever the version changes. This is because the playlistsList object can be large for asynchronous events.
         * @summary subscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsSubscribe(householdId, xSonosCorrId, options) {
            return PlaylistsApiFp(configuration).playlistsSubscribe(householdId, xSonosCorrId, options)(fetch, basePath);
        },
        /**
         * Use this command to unsubscribe to events in the playlists namespace.
         * @summary unsubscribe
         * @param {string} householdId Target householdId
         * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playlistsUnsubscribe(householdId, xSonosCorrId, options) {
            return PlaylistsApiFp(configuration).playlistsUnsubscribe(householdId, xSonosCorrId, options)(fetch, basePath);
        },
    };
};
/**
 * PlaylistsApi - object-oriented interface
 * @export
 * @class PlaylistsApi
 * @extends {BaseAPI}
 */
export class PlaylistsApi extends BaseAPI {
    /**
     * The getPlaylist command retrieves the track information associated with a particular playlist.   This command returns a PlaylistSummary object, which contains an array of PlaylistTrack objects.
     * @summary getPlaylist
     * @param {string} householdId Target householdId
     * @param {string} playlistId Target playlistId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistsApi
     */
    playlistsGetPlaylist(householdId, playlistId, xSonosCorrId, options) {
        return PlaylistsApiFp(this.configuration).playlistsGetPlaylist(householdId, playlistId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use the getPlaylists command to get the list of Sonos playlists for a household. Playlists do not include pinned items (any non-playable containers pinned to My Sonos) or Sonos favorites. See the Save your favorites section in the Sonos user guide for details about My Sonos.   Returns a playlistsList object, which is an array of playlist objects with a version number.
     * @summary getPlaylists
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistsApi
     */
    playlistsGetPlaylists(householdId, xSonosCorrId, options) {
        return PlaylistsApiFp(this.configuration).playlistsGetPlaylists(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * The loadPlaylist command activates a playlist within the default playback session. This command interrupts any active private playback sessions. Sonos adds tracks from the playlist to the queue and activates the queue. This prevents your app from overwriting user-curated queues.
     * @summary loadPlaylist
     * @param {string} groupId Target groupId
     * @param {PlaylistsLoadPlaylistBody} [body]
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistsApi
     */
    playlistsLoadPlaylist(groupId, body, xSonosCorrId, options) {
        return PlaylistsApiFp(this.configuration).playlistsLoadPlaylist(groupId, body, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to subscribe to events in the playlists namespace.   When subscribed, Sonos sends asynchronous versionChanged events when users update their Sonos playlists. Your app can then choose to fetch the playlists as needed whenever the version changes. This is because the playlistsList object can be large for asynchronous events.
     * @summary subscribe
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistsApi
     */
    playlistsSubscribe(householdId, xSonosCorrId, options) {
        return PlaylistsApiFp(this.configuration).playlistsSubscribe(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
    /**
     * Use this command to unsubscribe to events in the playlists namespace.
     * @summary unsubscribe
     * @param {string} householdId Target householdId
     * @param {string} [xSonosCorrId] Correlation UUID for data tracking purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaylistsApi
     */
    playlistsUnsubscribe(householdId, xSonosCorrId, options) {
        return PlaylistsApiFp(this.configuration).playlistsUnsubscribe(householdId, xSonosCorrId, options)(this.fetch, this.basePath);
    }
}
