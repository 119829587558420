import React, { useEffect, useState } from "react";
import { GroupsApiFactory, HouseholdsApiFactory } from "../museClient/api";
import { Configuration } from "../museClient/configuration";
import GroupPlaybackComponentWrapper from "./groupPlaybackComponentWrapper";
import HeaderComponent from "./headerComponent";
import GroupsInfoHandler from "../MuseDataHandlers/GroupsInfoHandler";
import { useRecoilState } from "recoil";
import groupsInfoAtom from "../Recoil/groupsInfoAtom";
import selectedGroupAtom from "../Recoil/selectedGroupAtom";


export default function MainPlayerComponent() {
    const [error, setError] = useState(false);
    const [fetchFlag, setFetchFlag] = useState(true);
    const [houseHoldId, setHouseholdId] = useState(null);
    const [groupId, setgroupId] = useState(null);

    const [groupsInfoState, setGroupsInfoState] = useRecoilState(groupsInfoAtom);
    const [selectedGroupState, setSelectedGroupState] = useRecoilState(selectedGroupAtom);

    const museClientConfig = new Configuration({
        accessToken: JSON.parse(window.localStorage.accessToken).token,
    });

    useEffect(() => {
        // Fetches households from Sonos API, calls handler function to update fetchHouseholdsController
        const householdsApi = new HouseholdsApiFactory(museClientConfig);
        householdsApi.householdsGetHouseholds()
            .then((houseHoldsResponse) => {
                setError(false);
                const _householdId = houseHoldsResponse["households"][0].id;
                setHouseholdId(_householdId);

                // Fetches current groups from Sonos API
                const groupsApi = new GroupsApiFactory(museClientConfig);
                groupsApi.groupsGetGroups(_householdId)
                    .then((groupsApiResponse) => {
                        const _groupId = groupsApiResponse["groups"][0].id;
                        setgroupId(_groupId);

                        const res = GroupsInfoHandler(groupsApiResponse);
                        res.householdId = _householdId;
                        setGroupsInfoState(res);

                        setSelectedGroupState({
                            groupGoneFlag: false,
                            groupId: _groupId,
                            groupName: groupsApiResponse["groups"][0].name
                        });

                        setFetchFlag(false);
                    })
                    .catch(function (error) {
                        setError(true);
                        return Promise.reject(error);
                    });
            })
            .catch(function (error) {
                setError(true);
                return Promise.reject(error);
            });

    }, []);

    return error === true ? (
        <div className="main_page">
            <HeaderComponent/>
            <br />
            <h1 className="oauthtext">Groups in this household could not be found.</h1>
        </div>
    ) : (!fetchFlag && <GroupPlaybackComponentWrapper
            groupId={groupId}
            museClientConfig={museClientConfig}
            householdId={houseHoldId}
        />
    );
}
